<template>
  <HeaderComponent />
  <div class="container mt-4">
    <div class="card " id="App">
      <div class="card-header">
        <h5>ON HIRE</h5>
      </div>
      <div class="card-body">
        <p class="mb-2"><small>All astrick(<span class="text-danger">* </span>) fields are mandatory</small></p>
        <div class="row ">

          <div class="col-md-6">
            <div class="form-group">
              <label for="">DRIVER NAME:<span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="formData.driverName"
                :class="{ 'is-invalid': !formData.driverName && submitted }">
              <small v-if="!formData.driverName && submitted" class="invalid-feedback">Driver Name is required.</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">LICENCE NO:<span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="formData.licenceNo"
                :class="{ 'is-invalid': !formData.licenceNo && submitted }">
              <small v-if="!formData.licenceNo && submitted" class="invalid-feedback">Licence Number is
                required.</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="froMTO">EXPIRY DATE:<span class="text-danger">*</span>

              </label>
              <input type="date" class="form-control" v-model="formData.expiryDate"
                :class="{ 'is-invalid': !formData.expiryDate && submitted }"  :min="new Date().toISOString().split('T')[0]" >
              <small v-if="!formData.expiryDate && submitted" class="invalid-feedback">Expiry Date is required.</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="froMTO">LICENCE TYPE:<span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="formData.licenceType"
                :class="{ 'is-invalid': !formData.licenceType && submitted }">
              <small v-if="!formData.licenceType && submitted" class="invalid-feedback">Licence type is
                required.</small>
            </div>
          </div>


          <div class="col-md-6">
            <div class="form-group">
              <label for="froMTO">TRUCK REGO:<span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="formData.truckRego"
                :class="{ 'is-invalid': !formData.truckRego && submitted }">
              <small v-if="!formData.truckRego && submitted" class="invalid-feedback">Truck rego is required.</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="kilometer" class="form-label">HUBO READING/OUT:</label>
              <input type="number" class="form-control" id="fromDate" v-model="formData.kilometerUsed"
              >
             
            </div>
          </div>
          
          <div class="col-md-12 mb-3">
            <hr/>
            <h5>Tyre Condition Details</h5>
            <!-- <div class="row mt-3">
              <div class="col-md-2 mb-3">
                <p>RECAP: <span class="badge badge-secondary">RC</span></p>
              </div>
              <div class="col-md-2">
                <p>NEW TYRE: <span class="badge badge-secondary">NT</span></p>
              </div>
              <div class="col-md-2">
                <p>PUNCTURE: <span class="badge badge-secondary">FT</span></p>
              </div>
              <div class="col-md-2">
                <p>DAMAGED TYRE: <span class="badge badge-secondary">DT</span></p>
              </div>
            </div> -->
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Axle 1</label>
                  <select class="form-control" v-model="formData.tyreConditionDetails.frontLeft.title">
                    <option value="" disabled selected>None</option>
      <option value="New">New</option>
      <option value="Good">Good</option>
      <option value="Acceptable">Acceptable</option>
      <option value="Recap">Recap</option>
      <option value="Puncture">Puncture</option>
      <option value="Damaged">Damaged</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for=""  class="form-label"><small>% at Collection</small></label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.frontLeft.percentage1">
                </div>
                <div class="form-group">
                  <label for=""  class="form-label"><small>% at Return</small></label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.frontLeft.percentage2" disabled>
                </div>
              </div>
             
              <div class="col-md-6">
                <div class="form-group">
                  <label>Axle 2</label>
                  <select class="form-control" v-model="formData.tyreConditionDetails.frontRight.title">
                    <option value="" disabled selected>None</option>
      <option value="New">New</option>
      <option value="Good">Good</option>
      <option value="Acceptable">Acceptable</option>
      <option value="Recap">Recap</option>
      <option value="Puncture">Puncture</option>
      <option value="Damaged">Damaged</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.frontRight.percentage1">
                </div>
                <div class="form-group">
                  <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.frontRight.percentage2" disabled>
                </div>
              </div>


            </div>

          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6">
                <label>Axle 3</label>
                <div class="form-group">
                  <select class="form-control" v-model="formData.tyreConditionDetails.centerLeft.title">
                    <option value="" disabled selected>None</option>
      <option value="New">New</option>
      <option value="Good">Good</option>
      <option value="Acceptable">Acceptable</option>
      <option value="Recap">Recap</option>
      <option value="Puncture">Puncture</option>
      <option value="Damaged">Damaged</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.centerLeft.percentage1">
                </div>
                <div class="form-group">
                  <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.centerLeft.percentage2" disabled>
                </div>
              </div>
             
              <div class="col-md-6">
                <div class="form-group">
                  <label>Axle 4</label>
                  <select class="form-control" v-model="formData.tyreConditionDetails.centerRight.title">
                    <option value="" disabled selected>None</option>
      <option value="New">New</option>
      <option value="Good">Good</option>
      <option value="Acceptable">Acceptable</option>
      <option value="Recap">Recap</option>
      <option value="Puncture">Puncture</option>
      <option value="Damaged">Damaged</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.centerRight.percentage1">
                </div>
                <div class="form-group">
                  <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.centerRight.percentage2" disabled>
                </div>
              </div>
             


            </div>

          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Axle 5</label>
                  <select  class="form-control" v-model="formData.tyreConditionDetails.rearLeft.title">
                    <option value="" disabled selected>None</option>
      <option value="New">New</option>
      <option value="Good">Good</option>
      <option value="Acceptable">Acceptable</option>
      <option value="Recap">Recap</option>
      <option value="Puncture">Puncture</option>
      <option value="Damaged">Damaged</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.rearLeft.percentage1">
                </div>
                <div class="form-group">
                  <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.rearLeft.percentage2" disabled>
                </div>
              </div>
           
              <div class="col-md-6">
                <div class="form-group">
                  <label>Axle 6</label>
                  <select class="form-control" v-model="formData.tyreConditionDetails.rearRight.title">
                    <option value="" disabled selected>None</option>
      <option value="New">New</option>
      <option value="Good">Good</option>
      <option value="Acceptable">Acceptable</option>
      <option value="Recap">Recap</option>
      <option value="Puncture">Puncture</option>
      <option value="Damaged">Damaged</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.rearRight.percentage1">
                </div>
                <div class="form-group">
                   <label for=""  class="form-label">&nbsp;</label>
                  <input type="number" class="form-control" placeholder="%"
                    v-model="formData.tyreConditionDetails.rearRight.percentage2" disabled>
                </div>
              </div>


            </div>
           
          </div>
          <div class="col-md-12">
            <label for="k_out">Note:</label>
                <div class="form-group">
       
        <textarea  
          rows="4" 
          class="w-100"
         v-model="formData.notes"
        ></textarea>
      </div>
              </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-md-12">
            <h4>Signature:<span class="text-danger">*</span></h4>
            <p>
              Please sign below to acknowledge your agreement with the Terms and
              Conditions:
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <canvas ref="signaturePad" style="border: 1px solid #000; width: 100%; height: 200px"></canvas>
          </div>
        </div>
        <!-- Buttons to clear and save signature -->
        <div class="row">
          <div class="col-md-12">
            <button @click="clearSignature" class="btn btn-secondary">
              Clear
            </button>
            <button id="save" @click="saveSignature" class="btn btn-primary ml-2">
              Save Signature
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
              <h6 class="mb-1">Decleration and sign by collecting driver</h6>
              <div class="mr-2">
                <label>
                  <input type="checkbox" class="" id="acceptTerms" style="width: 15px; height: 15px;"  :class="{ 'is-invalid': !ischeck && submitted }"
                  v-model="ischeck"/> 
                  <span>  Please ensure that the person hiring and dehiring TRI RENTALS equipment has a current drivers licence ,this must have the appropriate endorsement, a car licence is not the appropriate licence and must be rejected.</span>
                </label>
                <small
    v-if="!ischeck && submitted"
    class="invalid-feedback"
  >
    Please accept the terms and conditions.
  </small>
              </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" style="text-align: right; margin-top:4px; ">
        <button class="btn btn-secondary mb-3 mr-3" @click="goBack()">Back</button>
        <button class="btn btn-danger mb-3" @click="submitForm()">Submit</button>
      </div>

    </div>

  </div>
</template>

<script>
import SignaturePad from "signature_pad";
import axios from "axios";
import HeaderComponent from "./Header.vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import config from "@/config";

export default {
  name: "App",
  components: {
    HeaderComponent
  },
  data() {
    return {
      formData: {
        mounted:false,
        driverName: "",
        licenceNo: "",
        expiryDate: "",
        licenceType: "",
        truckRego: "",
        kilometerUsed: "",
        status: true,
        form_data_id: "",
        notes:"",
        tyreConditionDetails: {
          frontLeft: {
            title: "",
            percentage1: "",
            percentage2: ""
          },
          frontRight: {
            title: "",
            percentage1: "",
            percentage2: ""
          },
          centerLeft: {
            title: "",
            percentage1: "",
            percentage2: ""
          },
          centerRight: {
            title: "",
            percentage1: "",
            percentage2: ""
          },
          rearLeft: {
            title: "",
            percentage1: "",
            percentage2: ""
          },
          rearRight: {
            title: "",
            percentage1: "",
            percentage2: ""
          }
        }
      },

      signaturePad: null,
      imagePath: require("@/assets/images/logo.png"),
      submitted: false,
      $dataToSubmit:"",
      ischeck:false,
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.initSignaturePad();
      if (this.$route.params.id) {
      this.formData.form_data_id = this.$route.params.id;
  }
  }, 
  methods: {
    initSignaturePad() {
      const canvas = this.$refs.signaturePad;
      if (canvas) {
        this.signaturePad = new SignaturePad(canvas);
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
      } else {
        console.error("Canvas element not found");
      }
    },

    clearSignature() {
      if (this.signaturePad) {
        this.signaturePad.clear();
      }
    },

    saveSignature() {
      if (this.signaturePad.isEmpty()) {
        toast("Please provide a signature");
      } else {
        this.formData.signature = this.signaturePad.toDataURL();
        toast("Signature Saved");
      }
    },
    goBack() {
      this.$router.push("/list")
    },
    submitForm() {
   if(!this.ischeck){
    toast("Please Accept the Terms and Condition")
    return 
   }
      this.submitted = "true";
      const isValid =
        this.formData.driverName &&
        this.formData.licenceNo &&
        this.formData.expiryDate &&
        this.formData.licenceType &&
        this.formData.truckRego &&
     
        this.formData.tyreConditionDetails

      // Make sure tyre condition details are filled

      if (isValid) {
        this.formData.status = true;
        this.dataToSubmit = {
    driverName: this.formData.driverName,
    licenceNo: this.formData.licenceNo,
    expiryDate: this.formData.expiryDate,
    licenceType: this.formData.licenceType,
    truckRego: this.formData.truckRego,
    kilometerUsed: this.formData.kilometerUsed,
    status: this.formData.status,  // Explicitly set status
    tyreConditionDetails: this.formData.tyreConditionDetails,
    signature: this.formData.signature,
    form_data_id: this.formData.form_data_id,
    notes: this.formData.notes
  };
  console.log(this.formData);
  const token=localStorage.getItem('auth_token'); 
        axios
          .post(`${config.apiUrl}/on-hire/submit`, this.dataToSubmit, { headers: {
            Authorization: `Bearer ${token}`, // Add token to header
          }
        })
          .then((response) => {
    
            console.log(response.data);
            toast("form submited succesfully");

            this.resetForm();
            setTimeout(() => {
              this.$router.push("/list");
            }, 1000);
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast("There was an error submitting the form.");
          });
      } else {
        toast("Please fill in all required fields.");
      }
    },
    resetForm() {
      this.formData = {
        driverName: "",
        licenceNo: "",
        expiryDate: "",
        licenceType: "",
        truckRego: "",
        kilometerUsed: "",
        status: true,
        tyreConditionDetails: {
          frontLeft: { title: "", percentage1: "", percentage2: "" },
          frontRight: { title: "", percentage1: "", percentage2: "" },
          centerLeft: { title: "", percentage1: "", percentage2: "" },
          centerRight: { title: "", percentage1: "", percentage2: "" },
          rearLeft: { title: "", percentage1: "", percentage2: "" },
          rearRight: { title: "", percentage1: "", percentage2: "" }
        }
      };

      // Clear the signature if necessary
      this.clearSignature();
    }
  }
}
</script>