<template>
  <HeaderComponent />
  <div class="container">
    <div class="steps-container">
      <div class="steps">
        <div
          :class="{ 'steps__step': true, 'steps__step--current': progressWidth < 1, 'steps__step--done': progressWidth > 0 }"
          data-step="0">
          <div class="steps__step-number">1</div>
          <div class="steps__step-name">DE HIRE INFORMATION</div>
        </div>
        <div class="steps__connector"></div>
        <div
          :class="{ 'steps__step': true, 'steps__step--current': progressWidth >= 1, 'steps__step--done': progressWidth > 100 }"
          data-step="1">
          <div class="steps__step-number">2</div>
          <div class="steps__step-name">INVOICE DETAIL</div>
        </div>
      </div>
    </div>

    <div v-if="currentStep === 1" class="card">
      <div class="card-header">
        <h5>DE HIRE INFORMATION</h5>
      </div>
      <div class="card-body">
        <p class="mb-2"><small>All astrick(<span class="text-danger">* </span>) fields are mandatory</small></p>
        <div class="row">
          <div class="col-md-6">


            <div class="form-group">
              <label for="fromDate">DRIVER NAME:<span class="text-danger">* </span></label>
              <input type="text" class="form-control" id="fromDate" v-model="formData.driver_name"
                :class="{ 'is-invalid': !formData.driver_name && submitted }">
              <small v-if="!formData.driver_name && submitted" class="invalid-feedback">Driver name is required.</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">LICENCE NO:</label>
              <input type="text" class="form-control" id="" v-model="formData.licence_no">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">EXPIRY DATE:</label>
              <input type="date" class="form-control" id="" v-model="formData.expiry_date"
                :min="new Date().toISOString().split('T')[0]">

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="froMTO">LICENCE TYPE:</label>
              <input type="text" class="form-control" id="froMTO" v-model="formData.licence_type">
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="froMTO">TRUCK REGO:<span class="text-danger">* </span></label>
              <input type="text" class="form-control" id="froMTO" v-model="formData.truck_rego"
                :class="{ 'is-invalid': !formData.truck_rego && submitted }">
              <small v-if="!formData.truck_rego && submitted" class="invalid-feedback">Truck rego is required.</small>
            </div>

          </div>


        </div>
        <hr />
        <div class="row my-3">

          <div class="col-md-12">
            <h5>DE-HIRE EQUIPMENT INSPECTION</h5>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-8 mt-2">
            <label for="roofStatus" class="mb-0 form-label">ROOF:<span class="text-danger">* </span></label>
            <p><small>The roof of a trailer serves as a crucial protective element, shielding the cargo and interior
                from external elements like rain, wind, and sun.</small></p>
          </div>

          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="roofStatus" v-model="formData.roof"
              :class="{ 'is-invalid': !formData.roof && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>

          </div>


          <div class="col-md-8 mt-2">
            <label for="loadRacksStatus" class="mb-0 form-label">LOADRACKS:<span class="text-danger">* </span></label>
            <p><small>Load racks are essential for securing cargo during transportation. </small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="loadRacksStatus" v-model="formData.loadracks"
              :class="{ 'is-invalid': !formData.truck_loadracks && submitted }">
              <option value="NA">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.loadracks && submitted" class="invalid-feedback">loadracks field is required</small>
          </div>

          <div class="col-md-8 mt-2">
            <label for="suspensionStatus" class="mb-0 form-label">SUSPENSION:<span class="text-danger">* </span></label>
            <p><small>The suspension system of a trailer plays a critical role in ensuring a smooth and stable ride by
                absorbing shocks and vibrations from the road.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="suspensionStatus" v-model="formData.suspension"
              :class="{ 'is-invalid': !formData.suspension && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.suspension && submitted" class="invalid-feedback">suspension field is
              required</small>
          </div>

          <!-- T/BOX -->
          <div class="col-md-8 mt-2">
            <label for="tBoxStatus" class="mb-0 form-label">T/BOX:<span class="text-danger">* </span></label>
            <p><small>The trailer box (T/BOX) is essential for carrying cargo safely, protecting it from external
                conditions.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="tBoxStatus" v-model="formData.t_box"
              :class="{ 'is-invalid': !formData.t_box && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.t_box && submitted" class="invalid-feedback">t_box field is required</small>
          </div>

          <!-- FLAPS -->
          <div class="col-md-8 mt-2">
            <label for="flapsStatus" class="mb-0 form-label">FLAPS:<span class="text-danger">* </span></label>
            <p><small>Flaps are used to cover the rear of the trailer to protect the cargo, especially during loading
                and unloading.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="flapsStatus" v-model="formData.flapes"
              :class="{ 'is-invalid': !formData.truck_flapes && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.flapes && submitted" class="invalid-feedback">flapes field is required</small>
          </div>

          <!-- BRAKES -->
          <div class="col-md-8 mt-2">
            <label for="brakesStatus" class="mb-0 form-label">BRAKES:<span class="text-danger">* </span></label>
            <p><small>The braking system is crucial for safely stopping the trailer. Regular checks ensure proper
                function and safety.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="brakesStatus" v-model="formData.brakes"
              :class="{ 'is-invalid': !formData.brakes && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.brakes && submitted" class="invalid-feedback">brakes field is required</small>
          </div>

          <!-- DOORS -->
          <div class="col-md-8 mt-2">
            <label for="doorsStatus" class="mb-0 form-label">DOORS:<span class="text-danger">* </span></label>
            <p><small>Trailer doors provide access to the cargo space and ensure safe closure to prevent cargo
                loss.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="doorsStatus" v-model="formData.doors"
              :class="{ 'is-invalid': !formData.doors && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.doors && submitted" class="invalid-feedback">doors field is required</small>
          </div>

          <!-- GATES -->
          <div class="col-md-8 mt-2">
            <label for="gatesStatus" class="mb-0 form-label">GATES:<span class="text-danger">* </span></label>
            <p><small>The gates at the rear of the trailer provide an additional layer of security, preventing the cargo
                from spilling out.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="gatesStatus" v-model="formData.gates"
              :class="{ 'is-invalid': !formData.gates && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.gates && submitted" class="invalid-feedback">gates field is required</small>
          </div>

          <!-- L/LEGS -->
          <div class="col-md-8 mt-2">
            <label for="lLegsStatus" class="mb-0 form-label">L/LEGS:<span class="text-danger">* </span></label>
            <p><small>Landing legs stabilize the trailer when it is not connected to the towing vehicle, providing
                support and balance.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="lLegsStatus" v-model="formData.l_legs"
              :class="{ 'is-invalid': !formData.l_legs && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.l_legs && submitted" class="invalid-feedback">l_legs field is required</small>
          </div>

          <!-- TYRES -->
          <div class="col-md-8 mt-2">
            <label for="tyresStatus" class="mb-0 form-label">TYRES:<span class="text-danger">* </span></label>
            <p><small>Tyres support the weight of the trailer and ensure traction on the road. Regular tyre checks
                prevent blowouts and accidents.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="tyresStatus" v-model="formData.tyre"
              :class="{ 'is-invalid': !formData.tyre && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.tyre && submitted" class="invalid-feedback">tyre field is required</small>
          </div>

          <!-- PAINT -->
          <div class="col-md-8 mt-2">
            <label for="paintStatus" class="mb-0 form-label">PAINT:<span class="text-danger">* </span></label>
            <p><small>Paint protects the trailer from rust and weathering, ensuring its longevity and good
                appearance.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="paintStatus" v-model="formData.paints"
              :class="{ 'is-invalid': !formData.paints && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.paints && submitted" class="invalid-feedback">paints field is required</small>
          </div>

          <!-- TAIL LIGHTS -->
          <div class="col-md-8 mt-2">
            <label for="tailLightsStatus" class="mb-0 form-label">TAIL LIGHTS:<span class="text-danger">*
              </span></label>
            <p><small>Tail lights are essential for visibility and safety, signaling turns, stops, and direction of
                movement to other drivers.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="tailLightsStatus" v-model="formData.tails"
              :class="{ 'is-invalid': !formData.tails && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.tails && submitted" class="invalid-feedback">tails field is required</small>
          </div>

          <!-- CURTAINS -->
          <div class="col-md-8 mt-2">
            <label for="curtainsStatus" class="mb-0 form-label">CURTAINS:<span class="text-danger">* </span></label>
            <p><small>Curtaints offer protection to the cargo from the elements while allowing easy access to the load
                area for quick unloading and loading.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="curtainsStatus" v-model="formData.curtains"
              :class="{ 'is-invalid': !formData.curtains && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.curtains && submitted" class="invalid-feedback">curtains field is required</small>
          </div>

          <!-- L/BINDERS -->
          <div class="col-md-8 mt-2">
            <label for="bindersStatus" class="mb-0 form-label">L/BINDERS:<span class="text-danger">* </span></label>
            <p><small>Load binders help secure the load during transport, preventing cargo from shifting and causing
                accidents.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="bindersStatus" v-model="formData.l_binders"
              :class="{ 'is-invalid': !formData.l_binders && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.l_binders && submitted" class="invalid-feedback">l_binders field is required</small>
          </div>

          <!-- MUDGUARDS -->
          <div class="col-md-8 mt-2">
            <label for="mudguardsStatus" class="mb-0 form-label">MUDGUARDS:<span class="text-danger">* </span></label>
            <p><small>Mudguards help protect both the trailer and other vehicles from debris and dirt kicked up by the
                tyres.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="mudguardsStatus" v-model="formData.mudguards"
              :class="{ 'is-invalid': !formData.mudguards && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.mudguards && submitted" class="invalid-feedback">mudguards field is required</small>
          </div>

          <!-- SIGNS -->
          <div class="col-md-8 mt-2">
            <label for="signsStatus" class="mb-0 form-label">SIGNS:<span class="text-danger">* </span></label>
            <p><small>Signs on the trailer provide essential information to other drivers, including load limits and
                safety warnings.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="signsStatus" v-model="formData.signs"
              :class="{ 'is-invalid': !formData.signs && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.signs && submitted" class="invalid-feedback">signs field is required</small>
          </div>

          <!-- CHASSIS -->
          <div class="col-md-8 mt-2">
            <label for="chassisStatus" class="mb-0 form-label">CHASSIS:<span class="text-danger">* </span></label>
            <p><small>The chassis is the main frame of the trailer, providing structural integrity and supporting the
                entire load.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="chassisStatus" v-model="formData.chassis"
              :class="{ 'is-invalid': !formData.chassis && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.chassis && submitted" class="invalid-feedback">chassis field is required</small>
          </div>

          <!-- T/CARRIER -->
          <div class="col-md-8 mt-2">
            <label for="carrierStatus" class="mb-0 form-label">T/CARRIER:<span class="text-danger">* </span></label>
            <p><small>The trailer carrier is the component that connects the trailer to the towing vehicle, enabling
                safe and stable movement.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="carrierStatus" v-model="formData.t_carrier"
              :class="{ 'is-invalid': !formData.t_carrier && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.t_carrier && submitted" class="invalid-feedback">carrier field is required</small>
          </div>

          <!-- SIDE LIGHTS -->
          <div class="col-md-8 mt-2">
            <label for="sideLightsStatus" class="mb-0 form-label">SIDE LIGHTS:<span class="text-danger">*
              </span></label>
            <p><small>Side lights increase the visibility of the trailer, especially when turning or parked in low-light
                conditions.</small></p>
          </div>
          <div class="col-md-4 mt-2 pt-2">
            <select class="form-select" id="sideLightsStatus" v-model="formData.side_lights"
              :class="{ 'is-invalid': !formData.side_lights && submitted }">
              <option value="N/A">N/A</option>
              <option value="D">DAMAGE (D)</option>
              <option value="N">NOT DAMAGED (N)</option>
              <option value="R">REPAIRS REQ (R)</option>
              <option value="S">SERVICE DUE (S)</option>
              <option value="U">UNROADWORTHY (U)</option>
              <option value="RW">ROADWORTHY (RW)</option>
            </select>
            <small v-if="!formData.side_lights && submitted" class="invalid-feedback">side_lights field is
              required</small>
          </div>
        </div>





      </div>
    </div>
    <div v-if="currentStep === 2">
      <div class="card">
        <div class="card-header">
          <h5>DE HIRE INFORMATION</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <h5>ACCESSORIES ISSUED</h5>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(field, index) in formData.assessories" :key="index">
                      <td>{{ field.title }}</td>
                      <td>
                        {{ field.quantity }}
                      </td>
                    </tr>
                  </tbody>



                </table>
              </div>
              <div class="form-check   ">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="formData.check"
                  :value="true">
                <label class="form-check-label" for="flexCheckDefault">
                  Return In Good Condition
                </label>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-12">
              <hr />
              <h5>KILOMETERS USED:</h5>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="form-group">
                <label for="kilometer" class="form-label">KILOMETERS OUT:</label>
                <input type="number" class="form-control" id="kilometer" v-model="formData.k_out" disabled>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="kilometer" class="form-label">KILOMETERS IN:</label>
                <input type="number" class="form-control" id="kilometer" v-model="formData.k_in"
                v-on:input="calculateKms"  @input="validateKilometers">
                <small v-if="kInError" class="text-danger">Kilometers In must be greater than Kilometers Out.</small>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="kilometer" class="form-label">TOTAL KILOMETERS TRAVELED:</label>
                <input type="number" class="form-control" id="kilometer" v-model="formData.k_travelled" disabled>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" v-if="formData.hireKm && (longTermRental*1 < 1)">
                <label for="kilometer" class="form-label">EXCESS KILOMETERS:</label>
                <input type="number" class="form-control" id="kilometer" v-model="formData.excess_kilometer" disabled>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <hr />
              <h5>Axle Information</h5>
            </div>
            <div class="col-md-4" v-if="formData.tyreConditionDetails">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Axle 1</label>
                    <select class="form-control" v-model="formData.tyreConditionDetails.frontLeft.title" disabled>
                      <option value="" disabled selected>None</option>
                      <option value="New">New</option>
                      <option value="Good">Good</option>
                      <option value="Acceptable">Acceptable</option>
                      <option value="Recap">Recap</option>
                      <option value="Puncture">Puncture</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label"><small>% at Collection</small></label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.frontLeft.percentage1" disabled>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label"><small>% at Return</small></label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.frontLeft.percentage2">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Axle 2</label>
                    <select class="form-control" v-model="formData.tyreConditionDetails.frontRight.title" disabled>
                      <option value="" disabled selected>None</option>
                      <option value="New">New</option>
                      <option value="Good">Good</option>
                      <option value="Acceptable">Acceptable</option>
                      <option value="Recap">Recap</option>
                      <option value="Puncture">Puncture</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.frontRight.percentage1" disabled>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.frontRight.percentage2">
                  </div>
                </div>


              </div>

            </div>
            <div class="col-md-4" v-if="formData.tyreConditionDetails">
              <div class="row">
                <div class="col-md-6">
                  <label>Axle 3</label>
                  <div class="form-group">
                    <select class="form-control" v-model="formData.tyreConditionDetails.centerLeft.title" disabled>
                      <option value="" disabled selected>None</option>
                      <option value="New">New</option>
                      <option value="Good">Good</option>
                      <option value="Acceptable">Acceptable</option>
                      <option value="Recap">Recap</option>
                      <option value="Puncture">Puncture</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.centerLeft.percentage1" disabled>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.centerLeft.percentage2">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Axle 4</label>
                    <select class="form-control" v-model="formData.tyreConditionDetails.centerRight.title" disabled>
                      <option value="" disabled selected>None</option>
                      <option value="New">New</option>
                      <option value="Good">Good</option>
                      <option value="Acceptable">Acceptable</option>
                      <option value="Recap">Recap</option>
                      <option value="Puncture">Puncture</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.centerRight.percentage1" disabled>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.centerRight.percentage2">
                  </div>
                </div>



              </div>

            </div>
            <div class="col-md-4" v-if="formData.tyreConditionDetails">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Axle 5</label>
                    <select class="form-control" v-model="formData.tyreConditionDetails.rearLeft.title" disabled>
                      <option value="" disabled selected>None</option>
                      <option value="New">New</option>
                      <option value="Good">Good</option>
                      <option value="Acceptable">Acceptable</option>
                      <option value="Recap">Recap</option>
                      <option value="Puncture">Puncture</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.rearLeft.percentage1" disabled>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.rearLeft.percentage2">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Axle 6</label>
                    <select class="form-control" v-model="formData.tyreConditionDetails.rearRight.title" disabled>
                      <option value="" disabled selected>None</option>
                      <option value="New">New</option>
                      <option value="Good">Good</option>
                      <option value="Acceptable">Acceptable</option>
                      <option value="Recap">Recap</option>
                      <option value="Puncture">Puncture</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.rearRight.percentage1" disabled>
                  </div>
                  <div class="form-group">
                    <label for="" class="form-label">&nbsp;</label>
                    <input type="number" class="form-control" placeholder="%"
                      v-model="formData.tyreConditionDetails.rearRight.percentage2">
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-12">
              <label for="k_out">Note:</label>
              <div class="form-group">

                <textarea rows="4" class="form-control w-100" v-model="formData.note"></textarea>
              </div>
            </div>
            <div class="col-md-12" v-if="formData.notesOnhire">
              <label for="k_out"> OnHire Note :</label>
              <div class="form-group">

                <textarea rows="4" class="form-control w-100" v-model="formData.notesOnhire" disabled></textarea>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
              <h5>Invoice Details:</h5>
            </div>
            <div class="col-md-12 mt-3">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Total Cost</td>
                      <td><strong>${{ calculateCost() }}</strong></td>
                    </tr>
                    <!-- <tr v-else>
                  <td>Total Cost</td>
                  <td><strong>$0.00</strong></td>
                </tr> -->
                    <tr v-if="exKmPrice !== null && exKmPrice > 0 && (longTermRental*1 < 1)">
                      <td width="60%">Excessive KM </td>
                      <td>
                        <strong>${{ calculateExcessKilometer() }}</strong>

                      </td>
                    </tr>
                    <tr v-if="!formData.check">
                      <td width="60%">Damage</td>
                      <td>
                        <input type="number" class="form-control" placeholder="$" v-model="damage" />
                      </td>
                    </tr>
                    <tr>
                      <td width="60%">Less Deposit Paid</td>
                      <td>
                        <div>
                          <input type="number" class="form-control" placeholder="$" v-model="deposit" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%">GST (10%)</td>
                      <td><strong>${{ calculateGST() }}</strong></td>
                    </tr>
                    <tr>
                      <td width="60%">Total</td>
                      <td><strong>${{ calculateTotal() }}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
              <h5>Signature:<span class="text-danger">* </span></h5>
              <p>
                Please sign below to acknowledge your agreement with the Terms and
                Conditions:
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <canvas ref="signaturePad" style="border: 1px solid #000; width: 100%; height: 200px"></canvas>
            </div>
          </div>
          <!-- Buttons to clear and save signature -->
          <div class="row">
            <div class="col-md-12">
              <button @click="clearSignature" class="btn btn-secondary">
                Clear
              </button>
              <button id="save" @click="saveSignature" class="btn btn-primary ml-2">
                Save Signature
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>

  </div>
  <div class="button-container fixed-button-container">
    <button class="btn btn-secondary" @click="prevStep" :disabled="currentStep === 1">
      Prev
    </button>
    <button class="btn btn-primary" @click="currentStep === totalSteps ? submitForm() : nextStep()">
      {{ currentStep === totalSteps ? "Submit" : "Next" }}
    </button>
  </div>
</template>
<script>
import SignaturePad from "signature_pad";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
import HeaderComponent from "./Header.vue";
import config from "@/config";
import moment from "moment";

export default {
  name: "App",
  data() {
    return {
      formData: {
        driver_name: "",
        licence_no: "",
        expiry_date: "", // 
        licence_type: "",
        truck_rego: "",
        roof: "",
        loadracks: "",   //
        suspension: "",
        t_box: "",
        flapes: "",
        brakes: "",
        doors: "",
        gates: "",
        l_legs: "",
        tyre: "", //
        paints: "",
        tails: "",
        curtains: "",
        l_binders: "",
        mudguards: "",
        signs: "",
        chassis: "",
        t_carrier: "",
        side_lights: "",
        assessories: [{ title: "", quantity: "" }],
        check: false,
        k_out: "",
        k_in: "",
        k_travelled: "",
        excess_kilometer: "",
        signature: "",
        form_data_id: "",
        note: "",
        notesOnhire: "",

        tyreConditionDetails: {
          frontLeft: {
            title: "",
            percentage1: "",
           percentage2:"",
          },
          frontRight: {
            title: "",
            percentage1: "",
            percentage2: ""
          },
          centerLeft: {
            title: "",
            percentage1: "",
            percentage2: "",
         
          },
          centerRight: {
            title: "",
            percentage1: "",
            percentage2: "",
           
          },
          rearLeft: {
            title: "",
            percentage1: "",
            percentage2: "",
          
          },
          rearRight: {
            title: "",
            percentage1: "",
            percentage2: "",
           


          }
        },
       
        damage: 0
      },
      submitted: false,
      currentStep: 1,
      totalSteps: 2,
      signaturePad: null,
      travelDays: 0,
      hireKm: 0,
      price: 0,
      exKmPrice: 0,
      rate: 0,
      to: 0,
      from: 0,
      deposit: 0,
      damage: ``,
      longTermRental: null,
      kInError: false 
    };
  },
  computed: {
    progressWidth() {
      return ((this.currentStep - 1) / (this.totalSteps - 1)) * 100;
    }
  }, mounted() {
    window.scrollTo(0, 0);

    if (this.$route.params.id) {
      this.formData.form_data_id = this.$route.params.id;
    }
    this.fetchFormData();
  },
  components: {
    HeaderComponent
  },

  methods: {
    validateKilometers() {
    if (Number(this.formData.k_in) <= Number(this.formData.k_out)) {
      this.kInError = true;
    } else {
      this.kInError = false;
    }
  },
    async fetchFormData() {
      const token = localStorage.getItem('auth_token');
      try {
        const response = await axios.get(`${config.apiUrl}/form-data/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to header
          }
        }); // Adjust the endpoint as per your route
        if (response.data.status === "success") {
          this.formData.assessories = response.data.data.assessories;
          this.formData.k_out = response.data.kilometerUsed;
          this.formData.notesOnhire = response.data.notes;
          this.formData.tyreConditionDetails = response.data.data.tyreConditionDetails;
          this.travelDays = response.data.data.travel_days;
          this.hireKm = response.data.data.kilometer;
          this.price = response.data.data.price;
          this.exKmPrice = response.data.data.excessKilometer > 0 ? response.data.data.excessKilometer : 0;
          this.rate = response.data.data.rate;
          this.to = response.data.data.to;
          this.from = response.data.data.from;
          this.deposit = '-'+response.data.data.deposit_amount;
          this.longTermRental = response.data.data.longTermRental;
        } else {
          this.errorMessage = "Failed to retrieve form data.";
        }
      } catch (error) {
        console.error(error);
        this.errorMessage = "An error occurred while fetching the data.";
      }
    },
    back() {
      this.$router.push("/list");
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    calculateDays() {
      // Convert the from and to date strings to Date objects
      const fromDate = new Date(this.from);
      const toDate = new Date(this.to);

      // Calculate the difference in milliseconds
      const differenceInTime = toDate - fromDate;

      // Convert the difference from milliseconds to days
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      return differenceInDays;
    },
    getMonthDifference() {
      let startDate = moment(this.from);
      let endDate = moment(this.to);

      let monthsDifference = endDate.diff(startDate, 'months', true);

      // Round up if there's any fraction, to count partial months as whole
      monthsDifference = Math.ceil(monthsDifference);

      return monthsDifference;
    },
    calculateExcessKilometer() {
      if((this.longTermRental*1) > 0)
      {
        return 0;
      }
      if (this.rate == 'per_day') {
        let kilometer_travelled = (this.formData.k_in > 0 ? this.formData.k_in - this.formData.k_out : 0);
        let days = this.calculateDays();
        let actual = this.hireKm * days;
        let excess = kilometer_travelled - actual;
        return excess > 0 ? (excess * (this.exKmPrice / 100)) : 0;
      }
      else if (this.rate == 'per_week') {
        let kilometer_travelled = (this.formData.k_in > 0 ? this.formData.k_in - this.formData.k_out : 0);
        console.log(`kilometer_travelled`, kilometer_travelled);
        let days = this.calculateDays();
        let weeks = Math.ceil(days / 7);
        let actual = this.hireKm * weeks;
        let excess = kilometer_travelled - actual;
        return excess > 0 ? (excess * (this.exKmPrice / 100)) : 0;
      }
      else if (this.rate == 'per_month') {
        let kilometer_travelled = (this.formData.k_in > 0 ? this.formData.k_in - this.formData.k_out : 0);
        let months = this.getMonthDifference();
        let actual = this.hireKm * months;
        let excess = kilometer_travelled - actual;
        return excess > 0 ? (excess * (this.exKmPrice / 100)) : 0;
      }
    },
    calculateGST() {
      let damage = !this.formData.check && this.damage > 0 ? this.damage : 0;
      let subtotal = (this.calculateCost()*1 + this.calculateExcessKilometer()*1 + damage*1);
      return ((subtotal * 10) / 100).toFixed(2)*1;
    },
    calculateTotal() {
      let damage = !this.formData.check && this.damage > 0 ? this.damage : 0;
      let subtotal = (this.calculateCost()*1 + this.calculateExcessKilometer()*1 + damage*1 + this.deposit*1);
      let gst = this.calculateGST();
      return (subtotal + gst).toFixed(2) ;
    },
    nextStep() {
      if (this.currentStep === 1) {

        setTimeout(() => {
          this.initSignaturePad();
        }, 500);
      }
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      } else {
        this.submitForm();
      }
    },
    initSignaturePad() {
      const canvas = this.$refs.signaturePad;
      if (canvas) {
        this.signaturePad = new SignaturePad(canvas);
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        if (this.formData.signature) {
          const img = new Image();
          img.onload = () => {
            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
            setTimeout(() => {
              this.saveSignature();
            }, 100);
            
          };
          img.src = this.formData.signature;
        }
      } else {
        console.error("Canvas element not found");
      }
    },

    clearSignature() {
      if (this.signaturePad) {
        this.signaturePad.clear();
      }
    },

    saveSignature() {
      if (this.signaturePad.isEmpty()) {
        toast("Please provide a signature");
      } else {
        this.formData.signature = this.signaturePad.toDataURL();
        toast("Signature Saved");
      }
    },
    calculateKms() {
      let total = this.formData.k_in - this.formData.k_out;
      let totalTravled = total > 0 ? total : 0;
      this.formData.k_travelled = totalTravled;

    },

    calculateCost() {
      // longTermRental
      // formData.hireKm
      if((this.longTermRental*1) >= 1)
      {
        return this.price; 
      }
      else
      {
        let days = this.calculateDays();
        if (this.rate == 'per_week') {
          return Math.ceil(days / 7) * this.price;
        }
        else if (this.rate == 'per_day') {
          return (days * this.price);
        }
        else if (this.rate == 'per_month') {
          let months = this.getMonthDifference(this.from, this.to);
          return (months * this.price);

        }
      }
    },
    submitForm() {
      // Ensure that the signature is captured before submitting the form
      if (!this.formData.signature) {
        toast("Please provide a signature");
        return;
      }
      this.formData.form_data_id = this.$route.params.id;
      this.submitted = true;
      const isValid = this.formData.driver_name &&
        this.formData.truck_rego &&
        this.formData.roof &&
        this.formData.loadracks &&
        this.formData.suspension &&
        this.formData.t_box &&
        this.formData.flapes &&
        this.formData.brakes &&
        this.formData.doors &&
        this.formData.gates &&
        this.formData.l_legs &&
        this.formData.tyre &&
        this.formData.paints &&
        this.formData.tails &&
        this.formData.curtains &&
        this.formData.l_binders &&
        this.formData.mudguards &&
        this.formData.signs &&
        this.formData.chassis &&
        this.formData.t_carrier &&
        this.formData.side_lights &&
        this.formData.signature ;
      if (isValid) {
        let damage = !this.formData.check && this.damage > 0 ? this.damage : 0;
        this.formData.damage = damage;
        this.formData.deposit = this.deposit;
        const token = localStorage.getItem('auth_token');
        axios
          .post(`${config.apiUrl}/de-hire`, this.formData, {
            headers: {
              Authorization: `Bearer ${token}`, // Add token to header
            }
          })
          .then((response) => {
            console.log(response.data);
            toast("Form submitted successfully");
            this.resetForm();
            setTimeout(() => {
              this.$router.push("/list");
            }, 1000);
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast("There was an error submitting the form.");
          });
      } else {
        toast("Please fill in all required fields.");
      }
    },
    resetForm() {
      this.formData = {
        driver_name: "",
        licence_no: "",
        expiry_date: "",
        licence_type: "",
        truck_rego: "",
        roof: "",
        loadracks: "",
        suspension: "",
        t_box: "",
        flapes: "",
        brakes: "",
        doors: "",
        gates: "",
        l_legs: "",
        tyre: "",
        paints: "",
        tails: "",
        curtains: "",
        l_binders: "",
        mudguards: "",
        signs: "",
        chassis: "",
        t_carrier: "",
        side_lights: "",
        // accessories: [{ title: "", qtn: "", checkbx: "" }],
        k_out: "",
        k_in: "",
        k_travelled: "",
        excess_kilometer: "",
        signature: "" // Reset signature
      };
      this.clearSignature();
    }
  }
};
</script>