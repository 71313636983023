<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-5 login-section-wrapper">
                <div class="brand-wrapper">
                    <img :src="imagePath" alt="logo" class="logo">
                </div>
                <div class="login-wrapper my-auto">
                    <div v-if="otpScreen">
                        <h1 class="login-title">Enter OTP</h1>
                        <p>We have sent an OTP to your entered email.</p>
                        <form action="#!" @submit.prevent="authorize">
                            <div class="otp-input w-100">
                                <input type="number"  maxlength="1" v-model="otp1" required>
                               
                            </div>
                            <input class="btn btn-block login-btn" type="button" value="Submit" v-on:click="authorize">
                        </form>
                    </div>
                    <div v-else>
                        <h1 class="login-title">Log in</h1>
                        <p>Only authorized person can login and access the portal.</p>
                        <form @submit.prevent="login">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email" name="email" id="email" class="form-control"
                                    placeholder="email@example.com" v-model="email">
                            </div>
                            <input class="btn btn-block login-btn" type="button" value="Login" v-on:click="login">
                        </form>
                    </div>
                    <div style="margin-top: 50px;">
                        <small class=" pr-2">1 Babfield Court,Truganina VIC 3029 Phone:(03) 9131 1900</small><br/> 
                        <small class=" pr-2"><a href="mailto:admin@trirentals.com.au">admin@trirentals.com.au</a> | <a href="https://www.trirentals.com.au">www.trirentals.com.au</a></small>
                    </div>
                </div>
            </div>
            <div class="col-sm-7 px-0 d-none d-sm-block">
                <img src="https://auswidetrans.com.au/uploads/homepage/16722290747728-1.jpg" alt="login image" class="login-img">
            </div>
        </div>
    </div>
</template>
<script>
import config from '@/config';
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  name: "LoginPage",
  data(){
    return{
      imagePath: require("@/assets/images/logo.png"),
      otpScreen: false,
      email: '', 
    otp1:'',
   
    }
  },
  methods: {
   async  login()
    {
      
      try {
        const response = await axios.post(`${config.apiUrl}/login`, { email: this.email });
        console.log(response.data)
        if (response.data.status) {
          this.otpScreen = true;
          toast(response.data.message); // Display success message
        } else {
          toast(response.data.error); // Display error message if login failed
        }
      } catch (error) {
        console.error('Error during login:', error);
     return false;
    }

    },
   
    async authorize() {
        const otp = `${this.otp1}`;
        console.log(otp);
        if (otp.length !== 4) {
         toast('Please enter a valid 4-digit OTP.');
        return;
      }
      try {
        const response = await axios.post(`${config.apiUrl}/validate-otp`, {
          email: this.email,
          otp: otp,
        } );
        
        console.log('API Response Data:', response.data);
       
        if (response.data.status) {
            localStorage.setItem('auth_token', response.data.token);
          toast(response.data.message);
          setTimeout(() => {
              this.$router.push("/list");
            }, 1000);  // Successful login
          // Optionally redirect user or store token for further use
        } else {
          toast("Please enter valid otp");  // OTP validation failed
        }
      } catch (error) {
        console.error('Error during OTP validation:', error);
        toast('An error occurred while validating the OTP.');
      }
    }
    }
  }

</script>
