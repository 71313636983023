<template>
  <div id="app">
    <HeaderComponent />
    <div class="container container-fluid ">
      <div class="steps-container">
      <div class="steps">
        <div :class="{'steps__step': true, 'steps__step--current': progressWidth < 1, 'steps__step--done' : progressWidth > 0}" data-step="0">
          <div class="steps__step-number">1</div>
          <div class="steps__step-name">CUSTOMER DETAILS</div>
        </div>
        <div class="steps__connector"></div>
        <div :class="{'steps__step': true, 'steps__step--current': progressWidth >= 25, 'steps__step--done' : progressWidth > 50}" data-step="1">
          <div class="steps__step-number">2</div>
          <div class="steps__step-name">EQUIPMENT DETAILS </div>
        </div>
        <div class="steps__connector"></div>
        <div :class="{'steps__step': true, 'steps__step--current': progressWidth >= 50, 'steps__step--done' : progressWidth > 75}" data-step="2">
          <div class="steps__step-number">3</div>
          <div class="steps__step-name">RATE AND TERMS</div>
        </div>
        <div class="steps__connector"></div>
        <div :class="{'steps__step': true, 'steps__step--current': progressWidth >= 75, 'steps__step--done' : progressWidth >= 100}" data-step="3">
          <div class="steps__step-number">4</div>
          <div class="steps__step-name">TERMS AND CONDITIONS</div>
        </div>
      </div>
      </div>

      <div v-if="currentStep === 1" class="card">
            <div class="card-header">
              <h5>CUSTOMER DETAILS </h5>
            </div>
        <div class="card-body">
          <p class="mb-2"><small>All astrick(<span class="text-danger">* </span>) fields are mandatory</small></p>
          <form>
            <div class="row g-3">
              <div class="col-md-6">
                 <div class="form-group">
                    <label for="exampleFormControlInput1" class="form-label"
                      >COMPANY NAME:<span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="formData.companyName"
                      :class="{ 'is-invalid': !formData.companyName && submitted }"
                      id="exampleFormControlInput1"
                    />
                    <small
                      v-if="!formData.companyName && submitted"
                      class="invalid-feedback"
                      >Company name is required.</small
                    >
                  </div>
              </div>
              <div class="col-md-6">
                 <div class="form-group">
                <label for="inputPassword4" class="form-label">CONTACT PERSON:<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.contact"
                  :class="{ 'is-invalid': !formData.contact && submitted }"
                 
                />
                <small
                  v-if="!formData.contact && submitted"
                  class="invalid-feedback"
                  >Contact Number is required.</small
                >
              </div>
              </div>
              <div class="col-8">
                 <div class="form-group">
                <label for="inputAddress" class="form-label"
                  >COMPANY ADDRESS:<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.companyAddress"
                  :class="{
                    'is-invalid': !formData.companyAddress && submitted,
                  }"
                  id="inputAddress"
                />
                <small
                  v-if="!formData.companyAddress && submitted"
                  class="invalid-feedback"
                  >Company Address is required.</small
                >
              </div>
              </div>
              <div class="col-4">
                 <div class="form-group">
                <label for="inputstate" class="form-label">STATE:<span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  v-model="formData.state"
                  :class="{ 'is-invalid': !formData.state && submitted }"
                  id="use_type"
                >
                  <option value="">Select</option>
                  <option value="NSW">New South Wales-NSW</option>
                  <option value="QLD">Queensland-QLD</option>
                  <option value="SA">South Australia-SA</option>
                  <option value="TAS">Tasmania-TAS</option>
                  <option value="VIC">Victoria-VIC</option>
                  <option value="WA">Western Australia-WA</option>
                  <option value="ACT">Australian Capital Territory-ACT</option>
                  <option value="NT">Northern Territory-NT</option>
                </select>
                <small
                  v-if="!formData.state && submitted"
                  class="invalid-feedback"
                  >State is required.</small
                >
              </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                <label for="inputacccount" class="form-label"
                  >ACCOUNT NO:</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.accountNo"
                
                  id="inputaccount"
                />
              
              </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                <label for="inputarea" class="form-label">AREA CODE:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.areaCode"
              
                  id="inputarea"
                />
               
              </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                <label for="inputabn" class="form-label">ABN:</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="formData.abn"
               
                  id="inputabn"
                />
              </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                <label for="inputorder" class="form-label">ORDER NO:<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.orderNo"
                  :class="{ 'is-invalid': !formData.orderNo && submitted }"
                  id="inputorder"
                />
                <small
                  v-if="!formData.orderNo && submitted"
                  class="invalid-feedback"
                  >Order Number is required.</small
                >
              </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                <label for="inputmobile" class="form-label">MOBILE:<span class="text-danger">*</span></label>
                <input
      type="number"
      class="form-control"
      id="inputmobile"
      v-model="formData.mobile"
                @input="limitLength"
    :class="{ 'is-invalid': (!formData.mobile || formData.mobile.length !== 10 ||  isValidMobile(formData.mobile)) && submitted }"
       
     
    />
    <div v-if="submitted && (!formData.mobile || formData.mobile.length !== 10 || isValidMobile(formData.mobile))" class="invalid-feedback">
    Mobile number must be exactly 10 digits.
  </div>
              </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                <label for="inputfax" class="form-label">FAX:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.fax"
               
                  id="inputfax"
                />
            
              </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                <label for="inputphn" class="form-label">PHONE:(BUS)</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="formData.phoneBus"
                  id="inputphn"

                />
                
              </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                <label for="inputphonr" class="form-label">PHONE:(AH)</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="formData.phoneAh"
               
                  id="inputphonr"
                />
              </div>
              </div>
              <div class="col-md-4">
                 <div class="form-group">
                <label for="inputemail" class="form-label">EMAIL:<span class="text-danger">*</span></label>
                <input
                  type="email"
                  class="form-control"
                  v-model="formData.email"
                  :class="{ 'is-invalid': !formData.email && submitted }"
                  id="inputemail"
                />
                <small
                  v-if="!formData.email && submitted"
                  class="invalid-feedback"
                  >email is required.</small
                >
              </div>
              </div>
              <div class="col-12">
                 <div class="form-group">
                <label for="inputinsurence" class="form-label"
                  >PUBLIC LIABILITY INSURENCE CERTIFICATE OF CURRENCY NUMBER OR
                  POLICY NUMBER:<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="inputinsurence"
                  v-model="formData.policyNo"
                  :class="{ 'is-invalid': !formData.policyNo && submitted }"
                />
                <p>
                  <small
                    >NOTE: client's responsibility to show public liability
                    insurance before the hire agreement can be signed.</small
                  >
                </p>
                <small
                  v-if="!formData.policyNo && submitted"
                  class="invalid-feedback"
                  >Policy Number is required.</small
                >
              </div>
              </div>
              <div class="col-12">
                 <div class="form-group">
                <label for="inputcertificate" class="form-label"
                  >NON-OWNED TRAILER LIABILITY CERTIFICATE OF CURRENCY NUMBER OR
                  POLICY NUMBER:<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="inputcertificate"
                  v-model="formData.liabilityCertificate"
                  :class="{
                    'is-invalid': !formData.liabilityCertificate && submitted,
                  }"
                />
                <p>
                  <small
                    >PLEASE NOTE:in signing this agreement, the client
                    acknowledges it currently has and will maintain trailer
                    control insurance cover.</small
                  >
                </p>
                <small
                  v-if="!formData.liabilityCertificate && submitted"
                  class="invalid-feedback"
                  >LIABILITY CERTIFICATE is required.</small
                >
              </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-if="currentStep === 3" class="card">
        <div class="card-header">
        <h5>RATE AND TERMS</h5>
        </div>
        <div class="card-body">
          

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="use_type" class="form-label">Use Type:<span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  id="use_type"
                  v-model="formData.useType"
                  :class="{ 'is-invalid': !formData.useType && submitted }"
                >
                  <option value="">Select Use Type</option>
                  <option value="local">Local</option>
                  <option value="interstate">Interstate</option>
                  <option value="country">Country</option>
                  <!-- You can add more options if needed -->
                </select>
                <small
                  v-if="!formData.useType && submitted"
                  class="invalid-feedback"
                  >Use Type is required.</small
                >
              </div>
            </div>
            <div class="col-md-6" v-if="!formData.islongTermRentalChecked ">
              <div class="form-group">
                <label for="rate_type" class="form-label"
                  >Select Rate Type:<span class="text-danger">*</span></label
                >
                <select
                  class="form-control"
                  id="rate_type"
                  v-model="formData.rate"
                   :class="{ 'is-invalid': (formData.islongTermRentalChecked ? false : (!formData.rate && submitted)) }"
                    @change="onRateChange"
                >
                <!-- :class="{ 'is-invalid': !formData.rate && submitted }" -->
                  <option value="">Select Rate Type</option>
                  <option value="per_day">Per Day</option>
                  <option value="per_week">Per Week</option>
                  <option value="per_month">Per Month</option>
                </select>
                <!-- <small
                  v-if="!formData.rate && submitted"
                  class="invalid-feedback"
                  >Rate Type is required.</small
                > -->
                <small
                v-if="(formData.islongTermRentalChecked  ? false : (!formData.rate && submitted))"
                class="invalid-feedback"
                >rate is required.</small
              >
              </div>
            </div>
            <div class="col-md-6" v-else>
              <div class="form-group">
                <label for="rate_type" class="form-label"
                  >Select Rate Type:<span class="text-danger">*</span></label
                >
                <select
                  class="form-control"
                  id="rate_type"
                  
                    disabled
                >
                <!-- :class="{ 'is-invalid': !formData.rate && submitted }" -->
                  <option value="">Select Rate Type</option>
                  <option value="per_day">Per Day</option>
                  <option value="per_week">Per Week</option>
                  <option value="per_month">Per Month</option>
                </select>
               
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
              <label for="days" class="form-label">Price:<span class="text-danger">*</span></label>
              <input
                type="number"
                class="form-control"
                id="days"
                placeholder="$"
                v-model="formData.price"
                :class="{ 'is-invalid': !formData.price && submitted }"
              />
              <small
                v-if="!formData.price && submitted"
                class="invalid-feedback"
                >Price is required.</small
              >
            </div>
            </div>
            <div class="col-md-6 mt-3">
              <div class="form-group">
                <label class="form-label" for="flexCheckDefault1">
                  <input
                    class="form-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault1"
                    v-model="formData.islongTermRentalChecked"
                  /> &nbsp;Long Term Rental
                </label>
              </div>
            </div>
            <div class="col-md-6" v-if="!formData.islongTermRentalChecked">
              <div class="form-group">
              <label for="days" class="form-label">{{ rateLabel }} KILOMETER ALLOWANCE<span class="text-danger">*</span></label>
              <input
                type="number"
                class="form-control"
                id="days"
                placeholder=""
                v-model="formData.kilometer"
                :class="{ 'is-invalid': (formData.islongTermRentalChecked ? false : (!formData.kilometer && submitted)) }"
                 :readonly="isKilometerReadonly"
              />
              <small
                v-if="(formData.islongTermRentalChecked  ? false : (!formData.kilometer && submitted))"
                class="invalid-feedback"
                >Kilometer Allowance is required.</small
              >
            </div>
            </div>
            <div class="col-md-6" v-else>
              <div class="form-group">
              <label for="days" class="form-label">{{ rateLabel }} KILOMETER ALLOWANCE</label>
              <input
                type="number"
                class="form-control"
                id="days"
                placeholder=""
              
                 disabled
              />
              <!-- <small
                v-if="(formData.islongTermRentalChecked  ? false : (!formData.kilometer && submitted))"
                class="invalid-feedback"
                >Kilometer Allowance is required.</small
              > -->
            </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
              <label for="payment_method" class="form-label"
                >Select Payment Method:<span class="text-danger">*</span></label
              >
              <select
                class="form-control"
                id="payment_method"
                v-model="formData.payment"
                :class="{ 'is-invalid': !formData.payment && submitted }"
              >
                <option value="">Select a payment method</option>
                <option value="cod">C.O.D</option>
                <option value="credit_card">Credit Card</option>
                <option value="direct_debit">Direct Debit</option>
                <option value="cheque">Cheque</option>
                <option value="account">Account</option>
              </select>
              <small
                v-if="!formData.payment && submitted"
                class="invalid-feedback"
                >Payment Method is required.</small
              >
            </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
              <label for="days" class="form-label">Deposit Amount<span class="text-danger">*</span>:</label>
              <input
                type="number"
                class="form-control"
                id="days"
                placeholder=""
                v-model="formData.deposit_amount"
               :class="{ 'is-invalid': !formData.deposit_amount && submitted }"
              />
              <small
                v-if="!formData.deposit_amount && submitted"
                class="invalid-feedback"
                >Deposit Amount is required.</small
              >
            </div>
            </div>
            <div class="col-md-6" v-if="!formData.islongTermRentalChecked">
              <div class="form-group">
                <label class="form-label" for="flexCheckDefault">
                  <input
                    class="form-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    v-model="formData.isExcessKilometerChecked"
                  /> &nbsp;PER EXCESS KILOMETER (in Cents)
                </label>
                <input
                  v-if="formData.isExcessKilometerChecked"
                  type="text"
                  class="form-control"
                  id="days"
                  placeholder="10(CENT)"
                  v-model="formData.excessKilometer"
                />
              </div>
            </div>
            <div class="col-md-6" v-else>
              <div class="form-group">
                <label class="form-label" for="flexCheckDefault">
                  <input
                    class="form-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    v-model="formData.isExcessKilometerChecked"
                    disabled
                  /> &nbsp;PER EXCESS KILOMETER (in Cents)
                </label>
                <input
                  v-if="formData.isExcessKilometerChecked"
                  type="text"
                  class="form-control"
                  id="days"
                  placeholder="10(CENT)"
                  v-model="formData.excessKilometer"
                  disabled
                />
              </div>
            </div>
            
          </div>

          <div class="row mt-4">
            <div class="col-md-6">
              <h6 class="mb-2">HIRE PERIOD</h6>
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label for="fromDate">FROM:<span class="text-danger">*</span></label>
                    <input
                      type="date"
                      class="form-control"
                      id="fromDate"
                      v-model="formData.from"
                      :class="{ 'is-invalid': !formData.from  && submitted }"
                       :min="new Date().toISOString().split('T')[0]"
                    />
                   
                    <small
                      v-if="!formData.from && submitted"
                      class="invalid-feedback"
                      >date is required.</small
                    >
                  </div>
                  <div class="form-group">
                    <label for="froMTO">TO:<span class="text-danger">*</span></label>
                    <input
                      type="date"
                      class="form-control"
                      id="froMTO"
                      v-model="formData.to"
                      :class="{ 'is-invalid': (!formData.to || isToDateInvalid) && submitted }"
                       :min="new Date().toISOString().split('T')[0]"
                    />
                    <small
                      v-if="!formData.to && submitted"
                      class="invalid-feedback"
                      >date is required.</small
                    >
                    <small v-if="(!formData.to || isToDateInvalid) && submitted" class="invalid-feedback">TO date must be greater than FROM date.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>

        <!-- End of invoice details row -->
      </div>

      <div v-if="currentStep === 2" class="card">
        <div class="card-header">
          <h5>EQUIPMENT DETAILS</h5>
        </div>

        <div class="card-body">
          <div class="row">
            <!-- Column with USE TYPE heading -->
            <div class="col-md-6">
              <!-- Form fields under this heading -->
              <div class="form-group">
                <label>Trailer Type:<span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  id="payment_method"
                  placeholder="Select Trailer Type"
                  v-model="formData.trailerType"
                  :class="{ 'is-invalid': !formData.trailerType && submitted }"
                >
                  <option value="">Select a Trailer type</option>
                  <option>Double Drop Deck 4.6H Mezz - 46xx Series</option>
                  <option>34 Pallets Straight Deck -2xx Series</option>
                  <option>42 Pallet Super Drop Deck Mezzanine -42xx Series</option>
                  <option>Double Drop Deck Mezzanine -3xx series</option>
                  <option>36 Pallet Drop Deck Mezzanine -36xx Series</option>
                  <option>Drop Deck Mezzanine Trailers - 4xx Series</option>
                  <option>Pantech Rollback Trailers -25xx Series</option>
                  <option>Tautliner Rollback - 22xx Series</option>
                </select>
                <small
                  v-if="!formData.trailerType && submitted"
                  class="invalid-feedback"
                  >TrailerType is required.</small
                >
              </div>
            </div>
            <div class="col-md-2 ">
  <div class="form-check mt-3">
    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="formData.lead" :value="true">
    <label class="form-check-label" for="flexRadioDefault1">
      LEAD
    </label>
  </div>
</div>

<div class="col-md-2 ">
  <div class="form-check mt-3">
    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="formData.semi" :value="true">
    <label class="form-check-label" for="flexRadioDefault2">
      SEMI
    </label>
  </div>
</div>

<div class="col-md-2 ">
  <div class="form-check mt-3">
    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" v-model="formData.b_double" :value="true">
    <label class="form-check-label" for="flexRadioDefault3">
      B DOUBLE
    </label>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label for="registration" class="form-label">REGISTRATION:<span class="text-danger">*</span></label>
    <input
      type="text"
      class="form-control"
      id="registration"
      v-model="formData.registration"
      :class="{ 'is-invalid': !formData.registration && submitted }"
    />
    <small v-if="!formData.registration && submitted" class="invalid-feedback">Registration is required.</small>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label for="expiryDate" class="form-label">REGO. EXPIRY DATE:</label>
    <input
      type="date"
      class="form-control"
      id="expiryDate"
      v-model="formData.expiryDate"
     :min="new Date().toISOString().split('T')[0]"
    />
   
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label for="fleetNo" class="form-label">FLEET NO:<span class="text-danger">*</span></label>
    <input
      type="text"
      class="form-control"
      id="fleetNo"
      v-model="formData.fleetNo"
      :class="{ 'is-invalid': !formData.fleetNo && submitted }"
    />
    <small v-if="!formData.fleetNo && submitted" class="invalid-feedback">Fleet Number is required.</small>
  </div>
</div>

<!-- Conditional Fields for B DOUBLE -->
<div v-if="formData.b_double" class="col-md-4">
  <div class="form-group">
    <label for="registration2" class="form-label">REGISTRATION No 2:</label>
    <input
      type="text"
      class="form-control"
      id="registration2"
      v-model="formData.registration2"
      
    />
   
  </div>
</div>

<div v-if="formData.b_double" class="col-md-4">
  <div class="form-group">
    <label for="expiryDate2" class="form-label">REGO. EXPIRY DATE No 2:</label>
    <input
      type="date"
      class="form-control"
      id="expiryDate2"
      v-model="formData.expiryDate2"
      :min="new Date().toISOString().split('T')[0]"
    />
    
  </div>
</div>

<div v-if="formData.b_double" class="col-md-4">
  <div class="form-group">
    <label for="fleetNo2" class="form-label">FLEET NO 2:</label>
    <input
      type="text"
      class="form-control"
      id="fleetNo2"
      v-model="formData.fleetNo2"
     
    />

  </div>
</div>


            <div class="col-md-12">
              <h6 class="mb-2">ACCESSORIES ISSUED</h6>
              <!-- Loop through the inputFields and create a new row for each pair -->
              <div
                class="row"
                v-for="(field, index) in formData.assessories"
                :key="index"
              >
                <!-- Title input -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label :for="'inputTitle' + index">Title</label>
                    <input
                      v-model="field.title"
                      :id="'inputTitle' + index"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>

                <!-- Quantity input -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label :for="'inputQuantity' + index">Quantity</label>
                    <input
                      v-model="field.quantity"
                      :id="'inputQuantity' + index"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>&nbsp;</label><br />
                      <button
                        @click="removeInputField"
                        class="btn  btn-sm btn-danger"
                        :style="`margin-top: 5px;`"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
              </div>

              <!-- Add and Remove Buttons -->
              <div class="button-container">
                <button @click="addInputField" class="btn btn-sm btn-primary">Add</button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentStep === 4" class="card">
        <div class="card-header">
          <h5>Rental Terms and Conditions</h5>
        </div>
        <div class="card-body">
          

          <!-- Scrollable Terms and Conditions Text -->
          <div
            style="
              max-height: 300px;
              padding: 10px;
              overflow-y: auto;
              padding-right: 10px;
              border: 1px solid #ddd;
              margin-bottom: 10px;
            "
          >
            <h6>PAYMENT:</h6>
            <p>
              The rental schedule states how the amounts the customer must pay
              Tri Rentals are calculated and when and where those amounts are to
              be paid.
            </p>
            <p>
              The customer authorises Tri Rentals to obtain credit information
              about the customer to the extent permitted by any privacy
              legislation for the purpose of assessing the customer ability to
              meet its obligations under the terms of the hiring agreement.
            </p>

            <h6>THE CUSTOMER'S OBLIGATIONS:</h6>
            <ul>
              <li>
                It is the customer's obligation to ensure that only people with
                the appropriate training and licences use and operate the
                Equipment.
              </li>
              <li>
                It is the customer's obligation to ensure that any Tri Rentals
                approved service provider in accordance with the manufacturer
                instructions services the Equipment.
              </li>
              <li>
                It is the customer's obligation to ensure that the Equipment is
                maintained and in good working order and that the operator can
                perform routine and daily safety checks if applicable.
              </li>
              <li>
                The customer is obliged to notify Tri Rentals of any problem
                with the Equipment including any loss, damage, or accidents that
                the equipment may have been involved in.
              </li>
              <li>
                The customer must provide Tri Rentals with access to the
                Equipment to provide any services required under the terms of
                the hiring agreement.
              </li>
              <li>
                The customer must not alter, modify or attach anything to the
                Equipment without Tri Rentals' written consent.
              </li>
              <li>
                The customer must comply with all laws and regulations relating
                to the Equipment and its use.
              </li>
              <li>
                The customer must not use the Equipment for sub-hire, any speed
                test or contest.
              </li>
              <li>
                The customer must ensure that the Equipment is used strictly in
                accordance with the manufacturer's instructions and must not be
                overloaded or loaded in an incorrect manner.
              </li>
              <li>
                The Equipment is only to be used in the manner for which it was
                designed and intended.
              </li>
            </ul>

            <h6>THE CUSTOMER WARRANTS:</h6>
            <ul>
              <li>
                That all information supplied to Tri Rentals in relation to the
                hiring agreement by the customer is true and correct.
              </li>
              <li>
                That the customer has, prior to entering into the hiring
                agreement, inspected the Equipment and is satisfied that the
                Equipment complies with the description in the Rental Schedule
                both in its condition and suitability.
              </li>
              <li>
                That the person signing the hiring agreement is authorised by
                the customer to do so.
              </li>
              <li>
                Acknowledges that the hiring agreement does not pass the title
                of the Equipment to the customer.
              </li>
              <li>
                Must not remove or tamper with any identification of the
                Equipment.
              </li>
              <li>
                Must not represent to any person that the customer has title to
                the Equipment.
              </li>
              <li>
                Must not attempt to sell or grant any person any interest in the
                Equipment.
              </li>
              <li>
                Does authorise the Company to use the customer's name and/or act
                on behalf of the customer in exercising any rights or
                instituting or enforcing any legal proceedings which Tri Rentals
                thinks appropriate to protect its rights in the ownership of the
                Equipment.
              </li>
              <li>
                If the customer is more than one entity, the hiring agreement
                binds both entities jointly and severally.
              </li>
            </ul>

            <h6>INSURANCES AND INDEMNITIES:</h6>
            <p>
              The Company will maintain a minimum of $5,000,000 Public Liability
              Insurance and Workers Compensation Insurance for The Company's
              liability to third persons and The Company's workers.
            </p>
            <p>
              Tri Rentals is entitled to receive all insurance proceeds as a
              result of any loss or damage to any Tri Rentals Equipment.
            </p>
            <p>
              The customer indemnifies Tri Rentals, its employees, agents, and
              subcontractors against all losses, damage, expenses, and costs (on
              a full indemnity basis whether incurred by or awarded against any
              of them) that any of them sustain or incur as a result, whether
              directly or indirectly of the claim.
            </p>

            <h6>LIABILITY: LIABILITY OF TRI RENTALS AND CUSTOMER</h6>
            <p>
              In addition to any applicable legislation, the hiring agreement
              states the contractual liability of each party to the other party
              and neither party has any liability to the other for direct or
              indirect damage in relation to the Equipment, or delay or failure
              in supplying the Equipment or any other services, or the hiring
              agreement.
            </p>

            <h6>THE CUSTOMER ACCEPTS:</h6>
            <ul>
              <li>
                Risk in the Equipment while it is in the customer's control or
                possession.
              </li>
              <li>
                The liability for any damage to or loss of the Equipment caused
                by the customer's negligence or defective workmanship or that of
                its employees, agents, or contractors.
              </li>
              <li>The breach of the hiring agreement by the customer.</li>
              <li>
                The Company's liability for any implied warranty that cannot be
                excluded at law is limited to replacing or repairing the
                relevant Equipment or supplying the relevant services again.
              </li>
              <li>
                To provide evidence of non-owned trailer liability insurance
                cover. Evidence being a copy of the current policy or
                certificate of currency number.
              </li>
              <li>
                To provide evidence of adequate public liability insurance
                coverage. Evidence being a copy of the current policy or
                certificate of coverage number.
              </li>
            </ul>

            <h6>RENTALS TERMS:</h6>
            <p>
              The hiring agreement starts on the date it is signed and will
              continue to apply to all Equipment for at least the Hire Period
              specified in the hire/rental schedule for Equipment, unless
              terminated or extended in accordance with the following Terms and
              Conditions.
            </p>
            <p>
              The customer may terminate the hiring agreement before the end of
              the term by giving written notice to Tri Rentals of such
              termination.
            </p>
            <p>
              At any time, Tri Rentals may terminate the hiring agreement with
              immediate effect by giving notice to the customer.
            </p>
            <p>
              If the customer fails to pay any overdue amount owed to Tri
              Rentals within 7 days of receiving notice to do so.
            </p>
            <p>
              The customer breaches any other material term of the hiring
              agreement capable of remedy and fails to remedy the breach within
              14 days after receiving notice requiring doing so.
            </p>
            <p>
              The customer must notify The Company if any step is taken to enter
              into any arrangement between the customer and its creditors, or if
              the customer ceases to be able to pay its debts, or if any step is
              taken to appoint a receiver, a receiver and manager, a trustee in
              bankruptcy, a liquidator, provisional liquidator, an
              administration or any other like person of the whole or any part
              of the customer's assets.
            </p>

            <h6>
              DE-HIRING: IMMEDIATELY AT THE TERMINATION OF THE RENTAL/HIRING
              AGREEMENT FOR ANY EQUIPMENT
            </h6>
            <ul>
              <li>
                The customer must deliver to Tri Rentals the Equipment in good
                working order at its address or such other place as directed by
                Tri Rentals.
              </li>
              <li>
                The customer must pay Tri Rentals all monies the customer owes
                Tri Rentals in relation to the Equipment.
              </li>
              <li>
                The customer must pay Tri Rentals for each day until the
                Equipment is returned to Tri Rentals the amount payable daily as
                stated in the rental agreement.
              </li>
              <li>
                If the Equipment is not delivered in good working order and free
                of damage, the customer must pay Tri Rentals the cost of the
                repair required to return the Equipment to good working order
                and free of damage.
              </li>
            </ul>

            <h6>TERMINATION:</h6>
            <p>
              If the customer has not delivered any item of Equipment in good
              working order to Tri Rentals within 7 days of the termination of
              the hiring agreement for that Equipment, Tri Rentals may enter the
              premises at which the Equipment is or Tri Rentals think the
              Equipment is.
            </p>
            <p>
              Tri Rentals may take possession of the said Equipment and the
              customer must reimburse The Company on demand for expenses and
              costs (on a full indemnity basis) incurred by Tri Rentals in
              repossessing the Equipment or incidental to that action. The
              customer must reimburse or pay Tri Rentals on demand for all stamp
              duty payable in relation to the hiring agreement, and any document
              executed to give effect to the hiring agreement, and any taxes
              payable in connection with or pursuant to the hiring agreement or
              the Equipment.
            </p>
            <p>
              Notwithstanding any other provision in the rental/hiring
              agreement, Tri Rentals may, in addition to any amounts payable by
              the customer under the hiring agreement, require the customer to
              pay any amount of goods and services tax which is payable or may
              be payable by Tri Rentals in respect to the provision of any
              goods, services or other things to be done under the hiring
              agreement; and if Tri Rentals requires any amount to be paid by
              the customer, the customer must pay Tri Rentals that amount on
              demand. This clause survives termination of the hiring agreement.
            </p>

            <h3>ASSIGNMENT:</h3>
            <p>
              The customer may not transfer any of its rights or obligations
              under the hiring agreement without Tri Rentals' prior consent in
              writing. Tri Rentals may, by giving notice to the customer,
              transfer any of its rights and obligations under the hiring
              agreement to any person.
            </p>
            <p>
              The customer acknowledges that Tri Rentals may enter into the
              hiring agreement as an agent for another party.
            </p>

            <h6>TERMINOLOGY:</h6>
            <ul>
              <li>
                <strong>Company:</strong> means Tri Rentals and includes its
                successors, assigns, employees, servants, and agents.
              </li>
              <li>
                <strong>Contract:</strong> means the contract resulting from the
                acceptance of the company of a customer order.
              </li>
              <li>
                <strong>Customer:</strong> means any person who enters into a
                contract with the company for the rental/hire of Equipment and
                includes the customer's permitted assigns, successors,
                employees, servants, and agents.
              </li>
              <li>
                <strong>Equipment:</strong> means each item of equipment
                specified in the hire/rental schedule.
              </li>
              <li>
                <strong>Hire Period:</strong> means the period of hire
                contemplated in the hire/rental schedule.
              </li>
              <li>
                <strong>ShapeShape Rental Schedule:</strong> means the document
                the parties agree describes the Company will supply to the
                customer, and other relevant details about the Equipment and the
                hiring agreement.
              </li>
            </ul>
          </div>

          <!-- Checkbox for Acceptance -->
          <!-- <div class="form-check mt-3">
            <input type="checkbox" class="form-check-input" id="acceptTerms"   />
            <label class="form-check-label" for="acceptTerms">
              I accept the terms and conditions
            </label>
          </div> -->
          <div class="form-check mt-3">
  <input 
    type="checkbox" 
    class="form-check-input" 
    id="acceptTerms" 
    :class="{ 'is-invalid': !isChecked && submitted }"
    v-model="isChecked"
  />
  <label class="form-check-label" for="acceptTerms">
    I accept the terms and conditions
  </label>
  <small
    v-if="!isChecked && submitted"
    class="invalid-feedback"
  >
    Please accept the terms and conditions.
  </small>
</div>
          <br />
          <h4>Signature:<span class="text-danger">*</span></h4>
          <p>
            Please sign below to acknowledge your agreement with the Terms and
            Conditions:
          </p>

          <!-- Signature Canvas -->
          <div v-if="currentStep === 4">
            <canvas
              ref="signaturePad"
              style="border: 1px solid #000; width: 100%; height: 200px"
            ></canvas>
          </div>
          <!-- Buttons to clear and save signature -->
          <div>
            <button @click="clearSignature" class="btn btn-secondary">
              Clear
            </button>
            <button id="save" @click="saveSignature" class="btn btn-primary ml-2">
              Save Signature
            </button>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">REPRESENTATIVE:<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.advantageRep"
                  :class="{ 'is-invalid': !formData.advantageRep && submitted }"
                />
                <small
                  v-if="!formData.advantageRep && submitted"
                  class="invalid-feedback"
                >
                  Please select a advantage rep.
                </small>
              </div>
            </div>
          </div>
          <div class="col-12"></div>
        </div>
      </div>

      <!-- <div class="col-md-4">
                                <h5>ON HIRE</h5>
                               
                                <div class="card">
                                    <div class="card-body">
                                            <div class="form-group">
                                                <label for="fromDate">DRIVER NAME:</label>
                                                <input type="text" class="form-control" id="fromDate">
                                            </div>
                                            <div class="form-group">
                                                <label for="froMTO">LICENCE NO:</label>
                                                <input type="text" class="form-control" id="froMTO">
                                            </div>
                                            <div class="form-group">
                                                <label for="froMTO">EXPIRY DATE:

                                                </label>
                                                <input type="text" class="form-control" id="froMTO">
                                            </div>
                                            <div class="form-group">
                                                <label for="froMTO">LICENCE TYPE:</label>
                                                <input type="text" class="form-control" id="froMTO">
                                            </div>
                                            <div class="form-group">
                                                <label for="froMTO">SIGNATURE:</label>
                                                <input type="text" class="form-control" id="froMTO">
                                            </div>
                                            <div class="form-group">
                                                <label for="froMTO">DATE:</label>
                                                <input type="text" class="form-control" id="froMTO">
                                            </div>
                                            <div class="form-group">
                                                <label for="froMTO">TRUCK REGO:</label>
                                                <input type="text" class="form-control" id="froMTO">
                                            </div>
                                    </div>
                                </div>
                            </div> -->

      <!-- Column with RATES heading -->

      
    </div>
  </div>
  <div class="button-container fixed-button-container">
      <button
        class="btn btn-secondary"
        @click="prevStep"
        :disabled="currentStep === 1"
      >
        Prev
      </button>
      <button
        class="btn btn-primary"
        @click="currentStep === totalSteps ? submitForm() : nextStep()"
      >
        {{ currentStep === totalSteps ? "Submit" : "Next" }}
      </button>
    </div>
</template>
<script>
import SignaturePad from "signature_pad";
import axios from "axios";
import HeaderComponent from "./Header.vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import config from "@/config";

export default {
  name: "App",
  components: {
    HeaderComponent
  },
  data() {
    return {
      formData: {
        companyName: "",
        contact: "",
        companyAddress: "",
        state: "",
        accountNo: "",
        areaCode: "",
        abn: "",
        orderNo: "",
        mobile: "",
        fax: "",
        phoneBus: "",
        phoneAh: "",
        email: "",
        policyNo: "",
        liabilityCertificate: "",
        useType: "",
        rate: "",
        price: "",
        kilometer: "",
        payment: "",
        deposit_amount:"",
        excessKilometer: "",
        longTermRental:"",
        from: "",
        to: "",
        trailerType: "",
        lead:true,
        semi:false,
        b_double:false,
        registration: "",
        expiryDate: "",
        serialNo: "",
        fleetNo: "",
        registration2:"",
        fleetNo2:"",
        expiryDate2:"",
        advantageRep: "",
        assessories: [{ title: "", quantity: "" }]
      },
      isExcessKilometerChecked: false,
      islongTermRentalChecked: false,
      isChecked: false,
      submitted: false,
      currentStep: 1,
      totalSteps: 4,
      isToDateInvalid: false,
      signaturePad: null,
      imagePath: require("@/assets/images/logo.png"),
    };
    
  },
  watch: {
    'formData.lead'(newVal) {
      if (newVal) {
        this.formData.b_double = false;
        this.formData.semi = false;
      }
    },
    'formData.semi'(newVal) {
      if (newVal) {
        this.formData.b_double = false;
        this.formData.lead = false;
      }
    },
    'formData.b_double'(newVal) {
      if (newVal) {
        this.formData.lead = false;
        this.formData.semi = false;
      }
    }
  },
  computed: {
    progressWidth() {
      return ((this.currentStep - 1) / (this.totalSteps - 1)) * 100;
    }, 
  
    rateLabel() {
      // Dynamically return the label text based on the selected rate
      switch (this.formData.rate) {
        case "per_day":
          return "DAILY";
        case "per_week":
          return "WEEKLY";
        case "per_month":
          return "MONTHLY";
        default:
          return ""; // Default case if no rate is selected
      }
    },
    isKilometerReadonly() {
      // If rate is selected, allow editing of kilometer field
      return this.formData.rate === "";
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    const currentDate = new Date().toLocaleDateString(); // Get the current date in your locale format
    this.formData.Date = currentDate; 
  },
  methods: 
 
  {  isValidMobile(mobile) {
      // Check if the mobile number is exactly 10 digits
      return /^[0-9]{10}$/.test(mobile);
    },  validateStep() {
      this.submitted = true;

      if (this.currentStep === 1) {
      
        return (
          this.formData.companyName &&
          this.formData.contact &&
          this.formData.companyAddress &&
          this.formData.state &&
          this.formData.orderNo &&
          this.formData.mobile &&
          this.formData.email &&
          this.formData.policyNo &&
          this.formData.liabilityCertificate &&

        this.isValidMobile(this.formData.mobile) 
        );
      } else if (this.currentStep === 3) {
         if (this.formData.from && this.formData.to) {
        const fromDate = new Date(this.formData.from);
        const toDate = new Date(this.formData.to);
        if (toDate <= fromDate) {
          this.isToDateInvalid = true;
          return false; // TO date should be greater than FROM date
        } else {
          this.isToDateInvalid = false;
        }
      }
        return (
         
          this.formData.useType &&
          (this.formData.islongTermRentalChecked || this.formData.rate) &&
          this.formData.price &&
          (this.formData.islongTermRentalChecked || this.formData.kilometer) &&
          this.formData.payment &&
          this.formData.deposit_amount &&
          this.formData.from &&
          this.formData.to 
        );
      } else if (this.currentStep === 2) {
        return (
         
          this.formData.trailerType &&
          this.formData.registration &&
          
          this.formData.fleetNo
          
         
        );
      }else if(this.currentStep ===4){
          this.formData.advantageRep &&
          this.formData.signature
      }
      return true;
    },
    addInputField() {
       this.formData.assessories.push({ title: "", quantity: "" });
    },

    removeInputField(index) {
        if (this.formData.assessories.length > 1) {
        this.formData.assessories.splice(index, 1);
      }
    },

    initSignaturePad() {
      const canvas = this.$refs.signaturePad;
      console.log("Canvas element:", canvas);

      if (canvas) {
        this.signaturePad = new SignaturePad(canvas);
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        if (this.formData.signature) {
          const img = new Image();
          img.onload = () => {
            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
            setTimeout(() => {
              this.saveSignature();
            }, 100);
            
          };
          img.src = this.formData.signature;
        }
      } else {
        console.error("Canvas element not found");
      }
    },

    clearSignature() {
      if (this.signaturePad) {
        this.signaturePad.clear();
        this.formData.signature = null;
      }
    },

    saveSignature() {
  
        this.formData.signature = this.signaturePad.toDataURL();
        toast("Signature Saved");
      
    },
    nextStep() {
        this.submitted = true;
        if (this.currentStep === 3) {
  
    setTimeout(() => {
      this.initSignaturePad();
    }, 500); 
  }
      if (this.validateStep()) {
        this.submitted = false; // Reset validation trigger for the next step
        if (this.currentStep < this.totalSteps) {
          this.currentStep++;
        } else {
          this.submitForm(); // Submit on the last step
        }
      } else {
        toast("Please fill in all required fields for this step.");
      }
    },
  //   nextStep() {
  //      if (this.currentStep === 3) {
  
  //   setTimeout(() => {
  //     this.initSignaturePad();
  //   }, 500); 
  // }
  //     if (this.currentStep < this.totalSteps) {
  //       this.currentStep++;
  //     } else {
  //       this.submitForm();
  //     }
  //   },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    submitForm() {
      this.submitted = true; // Trigger validation messages

   // Ensure signature exists
   if (!this.formData.signature) {
      toast("Please provide a signature."); // Display validation error
      return; // Prevent submission
   }

   // Ensure terms and conditions checkbox is checked
   if (!this.isChecked) {
      toast("Please accept the terms and conditions."); // Display validation error
      return; // Prevent submission
   }

   // Perform final validation for the current step (optional but useful)
   if (!this.validateStep()) {
      toast("Please complete all required fields."); // Display validation error
      return; // Prevent submission
   }
   console.log("Terms Accepted:", this.isChecked);
        this.formData.longTermRental  = this.formData.islongTermRentalChecked ? 1 : 0;
        const token=localStorage.getItem('auth_token'); 
        if (this.formData.signature) {
          console.log("Terms Accepted:", this.isChecked);
          axios
            .post(`${config.apiUrl}/submitform`, this.formData,{ headers: {
            Authorization: `Bearer ${token}`, // Add token to header
          }
        })
            .then((response) => {
              toast("Form submitted successfully!");
              console.log(response.data);
              this.$router.push("/list");
            })
            .catch((error) => {
              console.error("Error submitting form:", error);
              toast("There was an error submitting the form.");
            });
        } else {
          toast("Please provide a signature.");
        }
      
    },
  },
};
</script>
   