
import { createRouter, createWebHistory } from 'vue-router'
import FormTruck from './components/FormTruck.vue'
import ListFormTruck from './components/ListFormTruck.vue'
import HirePage from '@/components/HirePage.vue'
import DeHirePage from '@/components/DeHirePage.vue'
import LoginPage from '@/components/LoginPage.vue'
import ViewPage from './components/ViewPage.vue'

const routes = [
  {
    path: '/rent',
    name: 'FormTruck',
    component: FormTruck,
    meta: {
      requiresAuth: true, 
    },
  },
  {
    path: '/list',
    name: 'ListFormTruck',
    component: ListFormTruck,
    meta: {
      requiresAuth: true, 
    },
  },
  { path: '/hire/:id', component: HirePage, name: 'HirePage' , meta: {
    requiresAuth: true, 
  },},
  {path:'/dehire/:id', component: DeHirePage, name:'DeHirePage', meta: {
    requiresAuth: true, 
  }, },
  {path:'/', component: LoginPage, name:'LoginPage' },
  {path:'/view/:id', component: ViewPage, name:'ViewPage' },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('auth_token'); // Check if the token exists in localStorage

  // If the route requires authentication and the user is not logged in, redirect to login
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'LoginPage' });
  } else {
    next(); // Allow navigation to the requested route
  }
});
export default router
