<template>
  <HeaderComponent />
  <div class="container mt-4" v-if="data">
    <button class="btn btn-danger mb-1" @click="back"> &#x2190; Back</button>
    <button @click="printPage" class="btn btn-danger mb-1" style="float: right;"> Print </button>
    <div class="card " id="printable-section-1" >
      <div class="card-header">
        <h5>#{{ data.id }} - {{ data.companyName }}</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5>
              {{ data.companyName }}
            </h5>
            <h6 class="mt-2">
              {{ data.contact }}
            </h6>
            <p class="small mb-1">{{ data.companyAddress }}, {{ data.state }}, {{ data.areaCode }}</p>
            <p class="small mb-1 mt-2"><strong>ABN:</strong> {{ data.abn }}</p>
            <p class="small mb-1"><strong>Mobile:</strong> {{ data.mobile }}</p>
            <p class="small mb-1" v-if="data.phoneBus"><strong>Phone (BUS):</strong> {{ data.phoneBus }}</p>
            <p class="small mb-1" v-if="data.phoneAh"><strong>Phone (AH):</strong> {{ data.phoneAh }}</p>
            <p class="small mb-1" v-if="data.fax"><strong>Fax:</strong> {{ data.fax }}</p>
          </div>
          <div class="col-sm-6">
            <h5 class="mb-6">Order No. #{{ data.orderNo }}</h5>
            <p class="small mb-1 mt-2"><strong>Account Number:</strong> {{ data.accountNo }}</p>
            <p class="small mb-1"><strong>Public Liability Insurance Policy No.:</strong> {{ data.policyNo }}</p>
            <p class="small mb-1"><strong>Non Owned Trailer Liability Policy No.:</strong> {{ data.liabilityCertificate
              }}</p>
            <p class="small mb-1"><strong>Created:</strong> {{ date(data.created_at) }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <hr />
            <h5 class="mb-3">Equipment Deails</h5>
            <div class="row">
              <div class="col-sm-8">
                <h6>Trailer Preference:</h6>
                <p class="mb-0 mt-2"> {{ data.trailerType }}</p>
              </div>
              <div class="col-sm-4">
                <h6>Trailer Type:</h6>
                <p class="mb-0 mt-2"> {{ data.lead ? `Lead` : (data.semi ? 'Semi' : 'B Double') }}</p>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-4">
                <h6>Fleet:</h6>
                <p class="mb-0 mt-2"> {{ data.fleetNo }}</p>
              </div>
              <div class="col-sm-4">
                <h6>Registration:</h6>
                <p class="mb-0 mt-2"> {{ data.registration }}</p>
              </div>
              <div class="col-sm-4">
                <h6>Expiry:</h6>
                <p class="mb-0 mt-2"> {{ formatDate(data.expiryDate) }}</p>
              </div>
            </div>
            <template v-if="data.fleetNo2 || data.registration2 || data.expiryDate2">
              <hr />
              <div class="row">
                <div class="col-sm-4">
                  <h6>Fleet:</h6>
                  <p class="mb-0 mt-2"> {{ data.fleetNo2 ? data.fleetNo2 : `` }}</p>
                </div>
                <div class="col-sm-4">
                  <h6>Registration:</h6>
                  <p class="mb-0 mt-2"> {{ data.registration2 ? data.registration2 : '' }}</p>
                </div>
                <div class="col-sm-4">
                  <h6>Expiry:</h6>
                  <p class="mb-0 mt-2"> {{ data.expiryDate2 ? formatDate(data.expiryDate2) : `` }}</p>
                </div>
              </div>


            </template>
            <hr />
            <h5 class="mb-3">ACCESSORIES ISSUED</h5>
            <template v-if="data && data.assessories">
              <div class="row">
                <div class="col-sm-8">
                  <h6>Accessory:</h6>
                </div>
                <div class="col-sm-4">
                  <h6>Quantity:</h6>
                </div>
              </div>
              <div class="row" v-for="(a, i) in data.assessories" v-bind:key="`acc${i}`">
                <div class="col-sm-8">
                  <p class="mb-0 mt-2"> {{ a.title ? a.title : 0 }}</p>
                </div>
                <div class="col-sm-4">
                  <p class="mb-0 mt-2"> {{ a.quantity ? a.quantity : 0 }}</p>
                </div>
              </div>
            </template>
          </div>
          <div class="col-sm-12 mt-4">
            <hr />
            <h5 class="mb-3">Rate & Terms</h5>

            <div class="row">
              <div class="col-sm-6">
                <h6>Use Type:</h6>
                <p class="mb-0 mt-2"> {{ formatData(data.useType) }}</p>
              </div>
              <div class="col-sm-6" v-if="data.rate">
                <h6>Rate Type:</h6>
                <p class="mb-0 mt-2"> {{ formatData(data.rate) }}</p>
              </div>
              <div class="col-sm-6 mt-3">
                <h6>Price:</h6>
                <p class="mb-0 mt-2">${{ formatData(data.price) }}</p>
              </div>
              <div class="col-sm-6 mt-3" v-if="data.kilometer">
                <h6>Kilometer Allowance:</h6>
                <p class="mb-0 mt-2"> {{ formatData(data.kilometer) }}km</p>
              </div>
              <div class="col-sm-6 mt-3">
                <h6>Payment Type:</h6>
                <p class="mb-0 mt-2"> {{ formatData(data.payment) }}</p>
              </div>
              <div class="col-sm-6 mt-3">
                <h6>Deposit Amount:</h6>
                <p class="mb-0 mt-2"> ${{ data.deposit_amount ? data.deposit_amount : 0 }}</p>
              </div>
              <div class="col-sm-6 mt-3">
                <h6>From:</h6>
                <p class="mb-0 mt-2"> {{ formatDate(data.from) }}</p>
              </div>
              <div class="col-sm-6 mt-3">
                <h6>To:</h6>
                <p class="mb-0 mt-2"> {{ formatDate(data.to) }}</p>
              </div>
              <div class="col-sm-12 mt-2" v-if="data.excessKilometer">
                <h6>Excess Kilometer (cent) :</h6>
                <p class="mb-0 mt-2">{{ data.excessKilometer }}</p>
              </div>
            </div>

          </div>
          <div class="col-md-12  mt-4">

            <hr />
            <h5 class="mb-3">Terms & Signature</h5>
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-8">
                    <h6>Terms:</h6>
                    <p class="mb-0 mt-2">Agreed to terms and conditions.</p>
                  </div>
                  <div class="col-sm-4">
                    <h6>Date:</h6>
                    <p class="mb-0 mt-2"> {{ formatDate(data.created_at) }}</p>
                  </div>
                  <div class="col-sm-12">
                    <hr />
                    <h6>Representative:</h6>
                    <p class="mb-0 mt-2"> {{ data.advantageRep }}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-2">
                <img :src="`${url}${data.signature}`" />
              </div>
            </div>

          </div>
        </div>

        

      </div>
    </div>
    <div class="card mt-4" v-if="data.on_hire" id="printable-section-2">
      <div class="card-header">
        <h5>Hiring Details</h5>
      </div>
      <div class="card-body">
          <div class="mb-xl-0 mb-6 text-heading">
            <div class="row">
              <div class="col-sm-4 mb-1 "><h6>Driver Name:</h6> <p class="mb-0 mt-2">{{ data.on_hire.driverName}}</p></div>
              <div class="col-sm-4 mb-1 "><h6>Licence No:</h6> <p class="mb-0 mt-2">{{ data.on_hire.licenceNo }}</p></div>
              <div class="col-sm-4 mb-1 "><h6>Expiry Date:</h6> <p class="mb-0 mt-2">{{ formatDate(data.on_hire.expiryDate)}}</p></div>
              <div class="col-sm-4 mb-1 "><h6>licence Type:</h6> <p class="mb-0 mt-2">{{ data.on_hire.licenceType}}</p></div>
              <div class="col-sm-4 mb-1 "><h6>Truck Rego:</h6> <p class="mb-0 mt-2">{{  data.on_hire.truckRego }}</p></div>
              <div class="col-sm-4 mb-1" v-if="data.on_hire.kilometerUsed"><h6>Hubo Reading:</h6> <p class="mb-0 mt-2">{{
                data.on_hire.kilometerUsed
                }}</p></div>
         
          </div>
            

          </div>

          <hr />
          <h5 class="mb-3 mt-3">Tyre Condition Details</h5>

          <div class="row">
            <div class="col-sm-8">
              <h6>Tyre Condition</h6>
            </div>
            <div class="col-sm-4">
              <h6>Tyre Used Out:</h6>
            </div>

          </div>
          <div class="row mt-3" v-for="(a, i) in data.on_hire.tyreConditionDetails" v-bind:key="`acc${i}`">
            <div class="col-sm-8 ">
              <p class="mb-0">Axle : {{ a.title }}</p>
            </div>
            <div class="col-sm-4">
              <p class="mb-0"> {{ a.percentage1 }}</p>
            </div>
          </div>

          <p v-if="data.on_hire.notes" class="mt-2"><strong>Note :</strong>{{ data.on_hire.notes }}</p>
          <hr />
          <h5 class="mb-3">Terms & Signature</h5>
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-8">
                  <h6>Terms:</h6>
                  <p class="mb-0">Agreed to terms and conditions.</p>
                </div>
                <div class="col-sm-4">
                  <h6>Date:</h6>
                  <p class="mb-0"> {{ formatDate(data.on_hire.created_at) }}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <img :src="`${url}${data.on_hire.signature}`" />
            </div>
          </div>
        </div>
      </div>
        <div class="card mt-4" v-if="data.de_hires" id="printable-section-3">
          <div class="card-header">
            <h5>Dehiring Details</h5>
          </div>
          <div class="card-body">
          <div class="mb-xl-0 mb-6 text-heading">
            
            <div class="row">
              <div class="col-sm-4 mb-1 "><h6>Driver Name:</h6> <p class="mb-0 mt-2">{{ data.de_hires.driver_name }}</p></div>
              <div class="col-sm-4 mb-1 "><h6>Licence No:</h6> <p class="mb-0 mt-2">{{ data.de_hires.licence_no }}</p></div>
              <div class="col-sm-4 mb-1 "><h6>Expiry Date:</h6> <p class="mb-0 mt-2">{{ data.de_hires.expiry_date }}</p></div>
              <div class="col-sm-4 mb-1 "><h6>licence Type:</h6> <p class="mb-0 mt-2">{{ data.de_hires.licence_type }}</p></div>
              <div class="col-sm-4 mb-1 "><h6>Truck Rego:</h6> <p class="mb-0 mt-2">{{ data.de_hires.truck_rego }}</p></div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <hr />
              <h5>De-hire Equipment Details:</h5>
            </div>
            <div v-for="(item, index) in equipmentDetails" :key="index" class="col-sm-4 mt-2">
              <h6>{{ item.label }}</h6>
              <p class="mb-0 mt-2">{{ getStatusText(data.de_hires[item.key])  }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <hr />
              <h5>Kilometer Used:</h5>
            </div>
            <div class="col-sm-6">
              <p><strong>Kilometer Out:</strong> {{ data.on_hire.kilometerUsed }}</p>
            </div>
            <div class="col-sm-6">
              <p><strong>Kilometer In:</strong> {{ data.de_hires.k_in }}</p>
            </div>
            <div class="col-sm-6">
              <p><strong>Total Kilometer Travelled:</strong> {{ data.de_hires.k_travelled }}</p>
            </div>
            <div class="col-sm-6" v-if="(data.longTermRental==0)" >
              <p><strong>Excess Kilometer:</strong> {{ calculateKmExcess() }}</p>
            </div>
            <div class="col-sm-6">
              <p><strong>Long Term Rental:</strong> {{ (data.longTermRental * 1 > 0 ? 'Yes' : 'No') }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
              <h5>Invoice Details:</h5>
            </div>
            <div class="col-md-12 mt-3">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Total Cost</td>
                      <td><strong>${{ calculateCost() }}</strong></td>
                    </tr>
                    <!-- <tr v-else>
                  <td>Total Cost</td>
                  <td><strong>$0.00</strong></td>
                </tr> -->
                    <tr v-if="(data.longTermRental * 1) < 1">
                      <td width="60%">Excessive KM </td>
                      <td>
                        <strong>${{ calculateExcessKilometer() }}</strong>

                      </td>
                    </tr>
                    <tr v-if="data.de_hires.damage">
                      <td width="60%">Damage</td>
                      <td>
                        <strong>${{ data.de_hires.damage }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td width="60%">Less Deposit Paid</td>
                      <td><strong>-${{ deposit > 0 ? deposit : '0.00' }}</strong></td>
                    </tr>
                    <tr>
                      <td width="60%">GST (10%)</td>
                      <td><strong>${{ calculateGST() }}</strong></td>
                    </tr>
                    <tr>
                      <td width="60%">Total</td>
                      <td><strong>${{ calculateTotal() }}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr />
          <h5 class="mb-3">Terms & Signature</h5>
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-8">
                  <h6>Terms:</h6>
                  <p class="mb-0">Agreed to terms and conditions.</p>
                </div>
                <div class="col-sm-4">
                  <h6>Date:</h6>
                  <p class="mb-0"> {{ formatDate(data.de_hires.created_at) }}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <img :src="`${url}${data.de_hires.signature}`" />
            </div>
          </div>

        </div>
      </div>
  </div>
</template>

<script>
import axios from "axios";
import HeaderComponent from "./Header.vue";
import 'vue3-toastify/dist/index.css';
import config from "@/config";
import moment from "moment";

export default {
  name: "App",
  components: {
    HeaderComponent
  },
  data() {
    return {
      data: null, // API response data
      url: config.url,
      rate: null,
      price: null,
      from: null,
      to: null,
      formData: {
        k_in: 0,
        k_out: 0,
        check: false,
        excess_kilometer: 0,
        k_travelled: 0
      },
      hireKm: 0, // Initialize for kilometers per time unit
      exKmPrice: 0, // Excess kilometer price
      deposit: 0,
      damage: 0,
      travelDays: 0, // Days of travel for hire period
      equipmentDetails: [
        { label: "Roof", key: "roof" },
        { label: "Loadracks", key: "loadracks" },
        { label: "Suspension", key: "suspension" },
        { label: "T/Box", key: "t_box" },
        { label: "Flapes", key: "flapes" },
        { label: "Brakes", key: "brakes" },
        { label: "Doors", key: "doors" },
        { label: "Gates", key: "gates" },
        { label: "L/Legs", key: "l_legs" },
        { label: "Tyre", key: "tyre" },
        { label: "Paints", key: "paints" },
        { label: "Tails", key: "tails" },
        { label: "Curtains", key: "curtains" },
        { label: "L-Binders", key: "l_binders" },
        { label: "Mudguards", key: "mudguards" },
        { label: "Signs", key: "signs" },
        { label: "Chassis", key: "chassis" },
        { label: "T/Carrier", key: "t_carrier" },
        { label: "Side Lights", key: "side_lights" },
        
      ],
      // Optional: A mapping of statuses to descriptive text
      statusMapping: {
        NA:"N/A",
        D: "Damaged",
        N: "Not Damaged",
        R: "Repairs Required",
        S: "Service Due",
        U: "Unroadworthy",
        RW: "Roadworthy",
      },
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.fetch();
  },
  methods: {
    

  printPage() {
    // Get the elements to print
    const section1 = document.getElementById('printable-section-1');
    const section2 = document.getElementById('printable-section-2');
    const section3 = document.getElementById('printable-section-3');
    const section4 = document.getElementById('printable-section-0');

    // Save the current content of the page
    const originalContent = document.body.innerHTML;
    const printButton = section4.querySelector('button');
  if (printButton) {
    printButton.style.display = 'none';
  }
    // Set the body content to only the printable sections
    document.body.innerHTML = `
    <div>${section4.innerHTML}</div>
      <div>${section1.innerHTML}</div>
   
      <div>${section2.innerHTML}</div>
 
      <div>${section3.innerHTML}</div>
    
    `;

    
    window.print();

    
    document.body.innerHTML = originalContent;

 
    this.$nextTick(() => {
      window.location.reload();
    });
  },

    formatData(value) {
      if (typeof value === 'string') {
        // Replace underscores with spaces and capitalize first letter of each word
        return value
          .replace(/_/g, ' ')                     // Replace underscores with spaces
          .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
      }
      return value; // If it's not a string, return the original value (e.g., number, date)
    },
    getStatusText(status) {
      return this.statusMapping[status] || "Unknown";
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    },
    back() {
      this.$router.back();
    },
    date(d) {
      return moment(d).format("D-M-Y H:m");
    },
    calculateDays() {
      const fromDate = new Date(this.from);
      const toDate = new Date(this.to);
      const differenceInTime = toDate - fromDate;
      return differenceInTime / (1000 * 3600 * 24);
    },
    getMonthDifference() {
      const startDate = moment(this.from);
      const endDate = moment(this.to);
      return Math.ceil(endDate.diff(startDate, "months", true));
    },
    calculateKmExcess() {
      const kilometer_travelled =
        this.formData.k_in > 0 ? this.formData.k_in - this.formData.k_out : 0;
      let actual = 0;
      if (this.rate === "per_day") {
        actual = this.hireKm * this.calculateDays();
      } else if (this.rate === "per_week") {
        const weeks = Math.ceil(this.calculateDays() / 7);
        actual = this.hireKm * weeks;
      } else if (this.rate === "per_month") {
        const months = this.getMonthDifference();
        actual = this.hireKm * months;
      }
      let excess = kilometer_travelled - actual;
      return excess;
    },
    calculateExcessKilometer() {
      if ((this.data.longTermRental * 1) > 0) {
        return 0;
      }
      const kilometer_travelled =
        this.formData.k_in > 0 ? this.formData.k_in - this.formData.k_out : 0;
      let actual = 0;
      if (this.rate === "per_day") {
        actual = this.hireKm * this.calculateDays();
      } else if (this.rate === "per_week") {
        const weeks = Math.ceil(this.calculateDays() / 7);
        actual = this.hireKm * weeks;
      } else if (this.rate === "per_month") {
        const months = this.getMonthDifference();
        actual = this.hireKm * months;
      }

      console.log(kilometer_travelled, actual);
      const excess = kilometer_travelled - actual;
      return excess > 0 ? excess * (this.exKmPrice / 100) : 0;
    },
    calculateGST() {
      const damage = !this.formData.check && this.damage > 0 ? this.damage : 0;
      const subtotal =
        this.calculateCost() * 1 +
        this.calculateExcessKilometer() * 1 +
        damage * 1;
      return (subtotal * 10) / 100;
    },
    calculateTotal() {
      const damage = !this.formData.check && this.damage > 0 ? this.damage : 0;
      const subtotal =
        this.calculateCost() * 1 +
        this.calculateExcessKilometer() * 1 +
        damage * 1 -
        this.deposit * 1;
      return subtotal + this.calculateGST() * 1;
    },
    calculateKms() {
      const total = this.formData.k_in - this.formData.k_out;
      const totalTravled = total > 0 ? total : 0;
      this.formData.k_travelled = totalTravled;
      const hireKm = this.travelDays * this.hireKm;
      const excessiveTraveled =
        hireKm > totalTravled ? totalTravled : totalTravled - hireKm;
      this.formData.excess_kilometer = excessiveTraveled;
    },
    calculateCost() {
      if ((this.data.longTermRental * 1) > 0) {
        return this.price;
      }

      const days = this.calculateDays();
      if (this.rate === "per_week") {
        return Math.ceil(days / 7) * this.price;
      } else if (this.rate === "per_day") {
        return days * this.price;
      } else if (this.rate === "per_month") {
        const months = this.getMonthDifference();
        return months * this.price;
      }
    },
    async fetch() {

      const token = localStorage.getItem("auth_token");
      const response = await axios.get(
        `${config.apiUrl}/form-data/view/${this.$route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      // Assign the response data
      const responseData = response.data.data;
      this.data = responseData;
      this.rate = responseData.rate;
      this.price = responseData.price;
      this.from = responseData.from;
      this.to = responseData.to;
      this.hireKm = responseData.kilometer;
      this.deposit = responseData.deposit_amount;
      this.exKmPrice = responseData.excessKilometer
      if (responseData.on_hire) {
        this.formData.k_out = responseData.on_hire.kilometerUsed;
      } else {

        this.formData.k_out = null;
      }
      this.travelDays = this.calculateDays();
      if (responseData.de_hires) {

        this.damage = responseData.de_hires.damage;
        this.formData.k_in = responseData.de_hires.k_in;
      } else {
        this.damage = null;
        this.formData.k_in = null;
      }
      // Initialize formData values


    },
  }
};
</script>
