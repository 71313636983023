<template>
  <header class="header" id="printable-section-0">
<div class="card-header">
      <div class="row">
        <div class="col-md-3">
          <a href="" v-on:click="logoClick">
            <img :src="imagePath" alt="My Image"  />
          </a>
        </div>
        <div class="col-md-9 text-right">
          <div class="d-inline-block">
            <small class=" pr-2">1 Banfield Court,Truganina VIC 3029 Phone:(03) 9131 1900</small><br/> 
            <small class=" pr-2"><a href="mailto:admin@trirentals.com.au">admin@trirentals.com.au</a> | <a href="https://www.trirentals.com.au">www.trirentals.com.au</a></small>
          </div>
          <div class="d-inline-block mx-3" >
            <button class="btn btn-danger float-right"  @click="logout()">Logout</button>
          </div>
        </div>
      </div>
</div>
</header>
</template>
<script>
import axios from 'axios';
import config from '@/config';

export default {
  name: 'HeaderComponent',
  
  data(){
    return{
      imagePath: require("@/assets/images/logo.png"),
    }
  },
  props: {
  },
  async mounted() {
    await this.authCheck();
  },
  methods: {
    logoClick() {
      this.$router.push('/list')
    },
    async authCheck() {
      const response = await axios.post(`${config.apiUrl}/protected`,
      {}, // You can add any request body if needed
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        
        }
      }
      );
      console.log(response);
      if(!response || !response.data.status)
      {
        this.$router.push('/')
      }
    },
    logout() {
      localStorage.removeItem('auth_token');
      this.$router.push('/')
    }
  }
};
</script>