<template>
  <HeaderComponent />
   
       
    <div class="container-fluid mt-4">
      <div class="card">
        <div class="card-header">
          <h5 class="float-left py-2">Rental Listing</h5>
          
          <button class="btn btn-danger float-right"  @click="add()">Create New</button>
        </div>
        <div class="card-body listing">
          <div class="table-responsive">

      
        <table class="table">
            
        <thead class="thead-dark">
            <tr>
              <th scope="col">ID</th> 
              <th style="min-width: 200px;" class="big" scope="col">Customer Name</th>
              <th class="big" scope="col">Contact Person</th>
              <th scope="col">Mobile No</th>
              <th scope="col">Email</th>
              <th scope="col">Order No</th>
              <th class="big" scope="col">Trailer Type</th>
              <th scope="col">Total Cost</th>
              <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody v-if="listing">
          <template v-for="(data,index) in listing" :key="data.id">
          <tr>
              <td>{{index + 1}}
                
              </td>
              <td>{{data.companyName}}<br />
                <button class="btn btn-sm btn-primary mt-3 mx-1 d-inline-block" @click="view(data.id)">View</button>
                <button class="btn btn-sm btn-primary mt-3 mx-1 d-inline-block" v-if="data.status === 'open'" @click="hireItem(data.id)">Hire</button>
                <button class="btn btn-sm btn-primary mt-3 mx-1 d-inline-block" v-else-if="data.status === 'hired'" @click="dehireItem(data.id)">DeHire</button></td>
              <td>{{data.contact}}</td>
              <td>{{data.mobile }}</td>
              <td>{{data.email}}</td>
              <td>{{data.orderNo}}</td>
              <td>{{data.trailerType}}</td>
              <td>{{calculateCost(data)}}</td>
          
              <td>
                <div v-if="data.status === 'open'">Open</div>
                <div v-else-if="data.status === 'hired'">Hired</div>
                <div v-else-if="data.status === 'dehired'">DeHired</div>
              </td>
          </tr>
          </template>
        </tbody>
        <tbody v-else>
            <tr><td colspan="8">No records found.</td></tr>
        </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import axios from 'axios'
import HeaderComponent from "./Header.vue";
import config from '@/config';
import moment from 'moment';

export default {
  name: "ListFormTruck",
  components: {
    HeaderComponent
  },
  data() {
    return {
      listing: [],
      orderNo: null,
      hire: false,
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.listing = [];
    this.fetchFormData();
  },
  methods: {
    view(id) {
      this.$router.push('/view/'+id)
    },
    add() {
      this.$router.push("/rent");
    },
    hireItem(id) {
     
      this.$router.push({ name: 'HirePage', params: { id: id } });
    },
    dehireItem(id){
      this.$router.push({name:'DeHirePage',params:{id:id}});
    },
    getMonthDifference(startDate, endDate) 
    {
      startDate = moment(startDate);
      endDate = moment(endDate);
      
      let monthsDifference = endDate.diff(startDate, 'months', true);
      
      // Round up if there's any fraction, to count partial months as whole
      monthsDifference = Math.ceil(monthsDifference);
      
      return monthsDifference;
    },
    async fetchFormData() {
      try {
        const token = localStorage.getItem('auth_token'); 
    
        const response = await axios.get(`${config.apiUrl}/form-data/list`,  { headers: {
            Authorization: `Bearer ${token}`, // Add token to header
          }
        });
        this.listing = response && response.data ? response.data.data : [];
      } catch (error) {
        console.error('Error fetching form data:', error);
      }
    },
    calculateCost(row) {
      if(row.rate == 'per_week')
      {
        return Math.ceil(row.days/7)*row.price;
      }
      else if(row.rate == 'per_day')
      {
        return (row.days*row.price);
      }
      else if(row.rate == 'per_month')
      {
        let months = this.getMonthDifference(row.from_date, row.to_date);
        return (months*row.price);

      }
    }
  }
}

</script>

